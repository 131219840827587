import loginFormImg1 from './img/login-1.png';
import loginFormImg2 from './img/login-2.png';
import loginFormImg3 from './img/login-3.png';
import paginationImg from './img/pagination.png';

export const MOCK_QUESTIONS_1 = [
	{
		q1: [
			'What is JavaScript, and what are its key features?',
			'How is JavaScript different from other programming languages?',
			'Where is JavaScript commonly used in web development?',
			'What is ECMAScript?',
			'Is JavaScript a compiled or interpreted language?',
			'Is there any relation between Java and JavaScript?',
		],
	},
	{
		q2: ['What are the differences between var, let, and const?'],
	},
	{
		q3: ['List and briefly describe the basic data types in JavaScript.'],
	},
	{
		q4: [
			'How can you check the type of a variable in JavaScript?',
			'What are the differences between == and ===?',
			'How do you convert a string to a number in JavaScript?',
			'What are the differences between integers and floating-point numbers in JavaScript?',
			'What is an undefined property?',
			'What is null value?',
			'What is the difference between null and undefined?',
			'What is isNaN?',
			'What are variable declaration and assignment in JavaScript?',
			'What are the differences between undeclared and undefined variables?',
			'What are identifiers in JavaScript and what are the rules to follow for identifiers?',
		],
	},
	{
		q5: [
			'What is String concatenation and how do you concatenate two strings in JavaScript?',
			'What are the differences between string concatenation and interpolation?',
			'Can you explain the concept of escaping characters in strings?',
		],
	},
	{
		q6: [
			'What is the use/purpose of functions in JavaScript?',
			'What are the some of string functions you mostly use in JavaScript?',
			'What are the some of number functions you mostly use in JavaScript?',
			'What are if-else statements in JavaScript and what are they used for?',
			'What is the ternary operator in JavaScript?',
			'What is the switch statement in JavaScript?',
			'What is the break statement in JavaScript?',
		],
	},
	{
		q7: [
			`How will the following expression be evaluated?  
	Please provide a detailed explanation of the result.
~~~js
(3 == "3" || 2 + 5 === "7") && !(5 < 10 || !false)
~~~`,
			`How will the following expression be evaluated?  
	Please provide a detailed explanation of the result.
~~~js
(!true && !false) || 5 * 2 === true * 10
~~~`,
			`How will the following expression be evaluated?  
	Please provide a detailed explanation of the result.
~~~js
"3" * "5" >= 15 && true == 1 && "   " == false && "" === 0
~~~`,
			`How will the following expression be evaluated?  
	Please provide a detailed explanation of the result.
~~~js
!(5 ** (10 / 5) === 25 || 5 === "5" || 3 >= 5)
~~~`,
			`How will the following expression be evaluated?  
	Please provide a detailed explanation of the result.
~~~js
!(2 + 2 === 8 / 2 && !false && 10 % 2 === 0) || 25 % 4 == "1"
~~~`,
			`How will the following expression be evaluated?  
	Please provide a detailed explanation of the result.
~~~js
(true && false) || (10 + 5 === "15" && "apple" !== "orange")
~~~`,
			`How will the following expression be evaluated?  
	Please provide a detailed explanation of the result.
~~~js
!(10 <= 20 && 3 * 2 === "6") && (5 + 5 !== 11 || false)
~~~`,
			`How will the following expression be evaluated?  
	Please provide a detailed explanation of the result.
~~~js
("Hello" + "World" === "HelloWorld" || 5 > 3) && (true && !false)
~~~`,
			`How will the following expression be evaluated?  
	Please provide a detailed explanation of the result.
~~~js
(4 >= 3 && 5 * 2 === "10") || !(7 !== "7" && "dog" === "cat")
~~~`,
			`How will the following expression be evaluated?  
	Please provide a detailed explanation of the result.
~~~js
(20 % 6 !== 0 && "apple" === "orange") || !(2 + 2 === 4 && true)
~~~`,
			`How will the following expression be evaluated?  
	Please provide a detailed explanation of the result.
~~~js
(!true && !false) && ("car" === "vehicle" || 3 ** 2 <= 10)
~~~`,
			`How will the following expression be evaluated?  
	Please provide a detailed explanation of the result.
~~~js
(5 + 3 >= "8" && 2 ** 3 === "8") || !(false || true)
~~~`,
			`How will the following expression be evaluated?  
	Please provide a detailed explanation of the result.
~~~js
(10 / 2 === 5 || "hello" + "world" === "helloworld") && (5 === "5" || true)
~~~`,
			`How will the following expression be evaluated?  
	Please provide a detailed explanation of the result.
~~~js
("hello" !== "world" && "apple" + "pie" === "applepie") || (5 + 5 !== "10" && 7 % 2 === 0)
~~~`,
			`How will the following expression be evaluated?  
	Please provide a detailed explanation of the result.
~~~js
(5 * 2 === 9 || true) && !(10 - 5 !== 5 && 10 % 3 === 1)
~~~`,
		],
	},
	{
		q8: [
			`## Find if a number is even or not.  
Write a program that generates a random number between 1 and 10 (both inclusive).  
If the random number is even, print \`true\`.  
Otherwise, print \`false\`.
~~~
Examples:
1 	-> false
2 	-> true
5 	-> false
10 	-> true
~~~`,
			`## Find if a number is odd or not.  
Write a program that generates a random number between 1 and 10 (both inclusive).  
If the random number is odd, print \`true\`.  
Otherwise, print \`false\`.
~~~
Examples:
1 	-> true
2 	-> false
5 	-> true
10 	-> false
~~~`,
			`## Find if a number is positive or not.  
Write a program that generates a random number between -5 and 5 (both inclusive).  
If the random number is positive, print \`true\`.  
Otherwise, print \`false\`.
~~~
Examples:
-5 	-> false
-1 	-> false
0 	-> false
1 	-> true
5 	-> true
~~~`,
			`## Find if a number is negative or not.  
Write a program that generates a random number between -5 and 5 (both inclusive).  
If the random number is negative, print \`true\`.  
Otherwise, print \`false\`.
~~~
Examples:
-5 	-> true
-1 	-> true
0 	-> false
1 	-> false
5 	-> false
~~~`,
			`## Find if a number is divisible by 5 or not.  
Write a program that generates a random number between 1 and 50 (both inclusive).  
If the random number is divisible by 5, print \`true\`.  
Otherwise, print \`false\`.
~~~
Examples:
1 	-> false
5 	-> true
20 	-> true
37 	-> false
50 	-> true
~~~`,
			`## Find if a number is divisible by 7 or not.  
Write a program that generates a random number between 1 and 50 (both inclusive).  
If the random number is divisible by 7, print \`true\`.  
Otherwise, print \`false\`.
~~~
Examples:
1 	-> false
7 	-> true
35 	-> true
49 	-> true
50 	-> false
~~~`,
			`## Calculate the sum of 2 random numbers.  
Write a program that generates 2 random numbers between 1 and 10 (both inclusive).  
Calculate the sum of the numbers and print it.
~~~
Examples:
3, 5	-> 8
7, 3	-> 10
5, 5	-> 10
1, 10	-> 11
10, 1	-> 11
~~~`,
			`## Calculate the absolute difference between 2 random numbers.  
Write a program that generates 2 random numbers between 1 and 10 (both inclusive).  
Calculate the absolute difference of the numbers and print it.
~~~
Examples:
3, 5	-> 2
7, 3	-> 4
5, 5	-> 0
1, 10	-> 9
10, 1	-> 9
~~~`,
			`## Calculate the product of 2 random numbers.  
Write a program that generates 2 random numbers between 1 and 10 (both inclusive).  
Calculate the product of the numbers and print it.
~~~
Examples:
3, 5	-> 15
7, 3	-> 21
5, 5	-> 25
1, 10	-> 10
10, 1	-> 10
~~~`,
			`## Calculate the square of a number.  
Write a program that generates a random numbers between 1 and 10 (both inclusive).  
Calculate the square of the number and print it.
~~~
Examples:
1 	-> 1
2 	-> 4
5 	-> 25
10 	-> 100
~~~`,
			`## Calculate the cube of a number.  
Write a program that generates a random number between 1 and 10 (both inclusive).  
Calculate the cube of the number and print it.
~~~
Examples:
1 	-> 1
2 	-> 8
5 	-> 125
10 	-> 1000
~~~`,
			`## Convert miles to kilometers.  
Write a program that generates a random number between 1 and 10 (both inclusive) to be considered as a mile unit.  
Convert miles unit to kilometers and print it.  
Please assume that 1 mile equals 1.6 kilometers.
~~~
Examples:
1 	-> 1.6
2 	-> 3.2
5 	-> 8
10 	-> 16
~~~`,
			`## Convert kilograms to pounds.  
Write a program that generates a random number between 1 and 10 (both inclusive) to be considered as a kilogram unit.  
Convert kilogram unit to pounds and print it.  
Please assume that 1 kilogram equals 2.2 pounds.

~~~js
Examples:
1 	-> 2.2
20 	-> 44
75 	-> 165
10 	-> 220
~~~`,
			`## Find if 2 numbers are equal or not.  
Write a program that generates 2 random numbers between 1 and 3 (both inclusive).  
If the numbers are equal, print \`true\`.  
Otherwise, print \`false\`.
~~~
Examples:
1, 1	-> true
1, 2	-> false
2, 3	-> false
2, 2	-> true
3, 3	-> false
~~~`,
			`## Find if an age is allowed to get a Driver's Licence or not.  
Write a program that generates a random number between 1 and 100 (both inclusive) to be considered as an age.  
If the age is more than or equal to 16, print \`true\`.  
Otherwise, print \`false\`.
~~~
Examples:
1 	-> false
15 	-> false
16 	-> true
45 	-> true
100	-> true
~~~`,
		],
	},
	{
		q9: [
			`## Find the greatest number between 2 random numbers.  
Write a program that generates 2 random numbers between 1 and 10 (both inclusive).  
Find the greatest of the numbers and print it.
~~~
Examples:
3, 5	-> 5
7, 3	-> 7
5, 5	-> 5
1, 10	-> 10
10, 1	-> 10
~~~`,
			`## Find the greatest number between 3 random numbers.  
Write a program that generates 3 random numbers between 1 and 10 (both inclusive).  
Find the greatest of the numbers and print it.
~~~
Examples:
3, 5, 2		-> 5
7, 3, 1		-> 7
5, 5, 5		-> 5
1, 10, 9	-> 10
10, 1, 2	-> 10
~~~`,
			`## Find the smallest number between 2 random numbers.  
Write a program that generates 2 random numbers between 1 and 10 (both inclusive).  
Find the smallest of the numbers and print it.
~~~
Examples:
3, 5	-> 3
7, 3	-> 3
5, 5	-> 5
1, 10	-> 1
10, 1	-> 1
~~~`,
			`## Find the smallest number between 3 random numbers.  
Write a program that generates 3 random numbers between 1 and 10 (both inclusive).  
Find the smallest of the numbers and print it.
~~~
Examples:
3, 5, 2		-> 2
7, 3, 1		-> 1
5, 5, 5		-> 5
1, 10, 9	-> 1
10, 1, 2	-> 1
~~~`,
			`## Calculate the average of 3 random numbers.  
Write a program that generates 3 random numbers between 1 and 10 (both inclusive).  
Calculate the average of the numbers and print it.
~~~
Examples:
3, 5, 7		-> 5
7, 3, 2		-> 4
5, 5, 5		-> 5
1, 10, 7	-> 6
10, 1, 1	-> 4
~~~`,
			`## Calculate the absolute difference between max and min of 3 random numbers.  
Write a program that generates 3 random numbers between 1 and 10 (both inclusive).  
Calculate the greatest and the smallest numbers and print their absolute difference.
~~~
Examples:
3, 5, 2		-> 3
7, 3, 1		-> 6
5, 5, 5		-> 0
1, 10, 9	-> 9
10, 1, 2	-> 9
~~~`,
			`## Find the quarter of a random number between 1 and 100.  
Write a program that generates a random number between 1 and 100 (both inclusive).  
Find which quarter of the range the number falls into and print it.  
1st quarter is 1-25  
2nd quarter is 26-50  
3rd quarter is 51-75  
4th quarter is 76-100
~~~
Examples:
15	-> 1st quarter
73	-> 3rd quarter
39	-> 2nd quarter
87	-> 4th quarter
~~~`,
			`## Find the midpoint of a random number between 1 and 100.  
Write a program that generates a random number between 1 and 100 (both inclusive).  
Find which half of the range the number falls into and print it.  
1st half is 1-50  
2nd half is 51-100
~~~
Examples:
15	-> 1st half
50	-> 1st half
51	-> 2nd half 
87	-> 2nd half 
100	-> 2nd half
~~~`,
			`## Find if sum of 2 random numbers is even or not.  
Write a program that generates 2 random numbers between 1 and 10 (both inclusive).  
If the sum of the random numbers is even, print \`true\`.  
Otherwise, print \`false\`.
~~~
Examples:
3, 5	-> true
7, 3	-> true
5, 5	-> true
1, 10	-> false
10, 1	-> false
~~~`,
			`## Find if product of 2 random numbers is odd or not.  
Write a program that generates 2 random numbers between 1 and 10 (both inclusive).  
If the product of the random numbers is odd, print \`true\`.  
Otherwise, print \`false\`.
~~~
Examples:
3, 5	-> true
7, 3	-> true
5, 5	-> true
1, 10	-> false
10, 1	-> false
~~~`,
		],
	},
	{
		q10: [
			`## Double The Word  
Write a function named as \`doubleWord()\` which takes a \`string\` word as an argument and returns the given word back doubled when invoked.  
*NOTE:* Assume you will not be given an empty word.
~~~js
Examples:
doubleWord("Tech")		-> "TechTech"
doubleWord("Global")	-> "GlobalGlobal"
~~~`,
			`## First Character  
Write a function named as \`firstCharacter()\` which takes a \`string\` word as an argument and returns the first character of the given word when invoked.  
*NOTE:* Assume you will not be given an empty word.
~~~js
Examples:
firstCharacter("Tech")		-> "T"
firstCharacter("Global")	-> "G"
~~~`,
			`## First Two Characters  
Write a function named as \`firstTwoCharacters()\` which takes a \`string\` word as an argument and returns the first two character of the given word when invoked.  
*NOTE:*  If the given word does not have 2 or more characters, then return the given \`string\` back.
~~~js
Examples:
firstTwoCharacters("Tech")		-> "Te"
firstTwoCharacters("Global")	-> "Gl"
firstTwoCharacters("")			-> ""
firstTwoCharacters(" ")			-> " "
firstTwoCharacters("1")			-> "1"
~~~`,
			`## Last Character  
Write a function named as \`lastCharacter()\` which takes a \`string\` word as an argument and returns the last character of the given word when invoked.  
*NOTE:* Assume you will not be given an empty word.
~~~js
Examples:
lastCharacter("Tech")	-> "h"
lastCharacter("Global")	-> "l"
lastCharacter(" ")		-> " "
lastCharacter("123")	-> "3"
~~~`,
			`## Last Two Characters  
Write a function named as \`lastTwoCharacters()\` which takes a \`string\` word as an argument and returns the last two characters of the given word when invoked.  
*NOTE:* If the given word does not have 2 or more characters, then return the \`string\` back.
~~~js
Examples:
lastTwoCharacters("Tech")	-> "ch"
lastTwoCharacters("Global")	-> "al"
lastTwoCharacters("")		-> ""
lastTwoCharacters(" ")		-> " "
lastTwoCharacters("1")		-> "1"
~~~`,
			`## First and Last Characters  
Write a function named as \`firstLast()\` which takes a \`string\` word as an argument and returns the first and the last characters of the given word when invoked.  
*NOTE:* If the given word does not have 2 or more characters, then return the \`string\` back.
~~~js
Examples:
firstLast("Tech")		-> "Th"
firstLast("TechGlobal")	-> "Tl"
firstLast("")			-> ""
firstLast(" ")			-> " "
firstLast("1")			-> "1"
firstLast("abcde")		-> "ae"
~~~`,
			`## Has Five  
Write a function named as \`hasFive()\` which takes a \`string\` word as an argument and returns \`true\` if given \`string\` has at least 5 characters, and \`false\` otherwise when invoked.  
~~~js
Examples:
hasFive("Tech")		-> false
hasFive("Global")	-> true
hasFive("")			-> false
hasFive("12345")	-> true
hasFive("hello")	-> true
~~~`,
			`## Middle  
Write a function named as \`middle()\` which takes a \`string\` word as an argument and returns the middle character if the \`string\` has odd length, and returns the middle two characters if the \`string\` has even length when invoked.  
*NOTE:* If the given word is empty, then return the empty \`string\` back.
~~~js
Examples:
middle("Tech")		-> "ec"
middle("Global")	-> "ob"
middle("abcde")		-> "c"
middle("1")			-> "1"
middle("abc")		-> "b"
middle("1234")		-> "23"
~~~`,
			`## Longer String  
Write a function named as \`longer()\` which takes two \`string\` words as arguments and returns the \`string\` that has more characters when invoked.  
*NOTE:* If both of the words have the same length, then return the first \`string\`.
~~~js
Examples:
longer("Tech", "Global")	-> "Global"
longer("Hello", "Hi")		-> "Hello"
longer("Hello", "World")	-> "Hello"
~~~`,
			`## Shorter String  
Write a function named as \`shorter()\` which takes two \`string\` words as arguments and returns the \`string\` that has less characters when invoked.  
*NOTE:* If both of the words have the same length, then return the second \`string\`.
~~~js
Examples:
shorter("Tech", "Global")	-> "Tech"
shorter("Hello", "Hi")		-> "Hi"
shorter("Hello", "World")	-> "World"
~~~`,
			`## Concat Two String  
Write a function named as \`concat()\` which takes two \`string\` words as arguments and returns the words concatenated when invoked.  
*NOTE:* Concatenation should always be as first \`string\` + second \`string\`.
~~~js
Examples:
concat("Tech", "Global")	-> "TechGlobal"
concat("Hello", "World")	-> "HelloWorld"
concat("", "abc")			-> "abc"
concat("123", "1234")		-> "1231234"
~~~`,
			`## Starts With Vowel  
Write a function named as \`startsVowel()\` which takes a \`string\` word as arguments and returns \`true\` if given string starts with a vowel letter, and \`false\` otherwise when invoked.  
*NOTE:* Vowel letters are A, E, O, U, I, a, e, o, u, i.
~~~js
Examples:
startsVowel("Tech")		-> false
startsVowel("Apple")	-> true
startsVowel("abc")		-> true
~~~`,
		],
	},
];

export const MOCK_QUESTIONS_2 = [
	{
		q1: [
			'What is SDLC?',
			'What are the stages of SDLC?',
			'What is the purpose of each stage in SDLC?',
			'Can you describe the different roles and responsibilities involved in the SDLC process?',
			'What is Agile and Waterfall, and how do they differ?',
			'What is the main difference between Scrum and Kanban?',
		],
	},
	{
		q2: [
			'Can you explain the Scrum framework and its key components, such as the Scrum Master, Product Owner, and Development Team?',
			'How does Scrum differ from other Agile methodologies?',
			'How do you differentiate between a project manager and a product owner in Agile methodology?',
			'What are the Scrum ceremonies, artifacts, and roles?',
			'What is Epic and who is responsible for creating and managing Epics?',
			'What is User Story and who is responsible for creating and managing User Stories?',
			'What is the acceptance criteria?',
			'Can you explain the difference between a product backlog and a sprint backlog?',
			'How do you prioritize items in the Product Backlog, and what factors should be considered?',
			'How do Product Backlog, Sprint Backlog, and Increment contribute to the Scrum framework?',
			'What is a Sprint?',
			'What is a Sprint Goal, and how does it relate to the Sprint Backlog?',
			'What is a Burndown Chart?',
		],
	},
	{
		q3: [
			'What are the three primary Scrum artifacts, and what is the purpose of each?',
			'What are Scrum Ceremonies?',
			"Can you explain the process of refining the Product Backlog, and how does the Scrum Team ensure that items are 'Ready' for an upcoming Sprint?",
			"What is the definition of 'Done' in Scrum, and how does it impact the Increment?",
			'What is a Parking Lot Meeting?',
			'What is the Three-Amigos Meeting?',
			'What is STLC?',
			'What are the stages of STLC?',
			'What is the purpose of each stage in STLC?',
		],
	},
	{
		q4: [
			'What is a Bug and who creates a Bug ticket?',
			'What is the Bug Life Cycle?',
			'What needs to be included in a Bug ticket?',
			'What is the difference between priority vs severity of a bug?',
			'Why do we need Bug Tracking Tools?',
			'What is a Test Case and what are Test Case components?',
			'What are differences between Test Scenario, Test Case and Test Script?',
			'What are the benefits of writing Test Cases?',
			'What are positive and negative Test Cases?',
			'What is Happy Path Testing?',
			'What are Boundary or Edge Test Cases?',
			'What is the Requirement Traceability Matrix (RTM)?',
		],
	},
	{
		q5: [
			'What is Software Testing and what are the different types of Software Testing?',
			'What are white-box and black-box testing? Give some examples.',
			'What is Unit Testing and Integration Testing and what are their differences?',
			'What is Smoke Testing and Regression Testing and what are their differences?',
			'What is Sanity Testing and when to perform it?',
			'What is End-to-End Testing and what are the benefits it provides?',
			'What is Performance Testing and why is it needed? What are different types of Performance Testing?',
			'What is User Acceptance Testing (UAT) and when to perform it?',
			'What is Alpha Testing and Beta Testing and who performs them?',
			'What is A/B Testing and why is it needed?',
			'What is Monkey (Ad Hoc) Testing and what are the benefits it provides?',
			'What is Security Testing and what are the benefits it provides?',
		],
	},
	{
		q6: [
			'How does JavaScript differ from compiled languages, and what unique features characterize JavaScript as an interpreted language?',
			"Can you explain what NodeJS is and why it's important for JavaScript?",
			"What are the differences between 'var', 'let', and 'const'?",
			'List and briefly describe the basic data types in JavaScript.',
			"What is the difference between 'null' and 'undefined' in JavaScript?",
			"What are the differences between '=' and '==' and '==='?",
			"What are the 'template literals' in JavaScript?",
		],
	},
	{
		q7: [
			'What is an array in JavaScript and why do we need it?',
			'What are the differences between array slice and splice methods?',
			'What are the differences between array map, filter and reduce methods?',
			'What are the differences between array some and every method?',
			'How do you sort elements in an array in JavaScript?',
			'What are loops in JavaScript?',
		],
	},
	{
		q8: [
			'What are function expressions?',
			'What are lambda or arrow functions in JavaScript?',
			'What is a callback function and why do we need it? ',
			'What is callback hell?',
			'What are enhanced object literals in JavaScript?',
			'What is the currying function in JavaScript?',
		],
	},
	{
		q9: [
			'What are the spread operator and rest parameters and differences between them?',
			'What is the difference between Shallow and Deep copy?',
			'What is nullish coalescing operator (??)?',
		],
	},
	{
		q10: [
			'What is this keyword and why do we use it in JavaScript?',
			'What is a super keyword and why do we use it in JavaScript?',
			'What is immutable in JavaScript? Explain in detail.',
			'What is scope in JavaScript? Explain in detail.',
		],
	},
	{
		q11: [
			'What is JSON, why do we need it and what are its common operations?',
			'What is serialization and deserialization?',
			'What is hoisting in JavaScript?',
		],
	},
	{
		q12: [
			'What are closures in JavaScript?',
			'What are bind, call and apply methods? Why do we use them?',
			'What is IIFE (Immediately Invoked Function Expression)?',
		],
	},
	{
		q13: [
			'What is AJAX?',
			'What are the different ways to deal with Asynchronous Code?',
			'What is promise, why do we need it and what are the states of promise?',
			'What is a call stack in JavaScript?',
			'What is an event loop in JavaScript?',
			'What is an async function in JavaScript?',
			'What are modules and why do we need them?',
		],
	},
	{
		q14: [
			'How does prototypal inheritance work in JavaScript?',
			'What is a prototype chain in JavaScript?',
			'What is a constructor, why do we need it and how do we use it?',
			'What are classes in ES6, how do you extend and why do we need it?',
			'What are static properties and methods in JavaScript?',
		],
	},
	{
		q15: [
			'What are linux commands and can you give some examples that you use?',
			'What is GIT vs VCS?',
			'What are the differences between GIT and hosting platforms like GitHub?',
			'What is an SSH Key?',
			'What is GIT branching?',
			'What are some GIT commands you use?',
			'What is a Pull Request?',
			'What is a merge conflict and how to resolve it?',
		],
	},
	{
		q16: [
			`## Double or Triple The Word
Write a function named as \`doubleOrTripleWord()\` which takes a \`string\` word as an argument and returns the given word back tripled if the string length is even or doubled if the string length is odd when invoked.

~~~js
Examples:
doubleOrTripleWord("Tech")   	-> "TechTechTech"
doubleOrTripleWord("Apple")   	-> "AppleApple"
doubleOrTripleWord("")   		-> ""
doubleOrTripleWord(" ")   		-> " "
doubleOrTripleWord("1")   		-> "11"
doubleOrTripleWord("22")   		-> "222222"
~~~`,
			` ## First and Last Word
Write a function named as \`firstlastWord()\` which takes a \`string\` word as an argument and returns the first and last words from the given string when invoked.
NOTE: Return empty string if the given string does not have any word.

~~~js
Examples:
firstLastWord("Hello World")   	        -> "HelloWorld"
firstLastWord("I like JavaScript")  	-> "IJavaScript"
firstLastWord("Hello")   		        -> "HelloHello"
firstLastWord("")   			        -> ""
firstLastWord("	")   		            -> ""
~~~`,
			`## Has Vowel
Write a function named \`hasVowel()\` which takes a \`string\` argument and returns \`true\` if the string has a vowel, returns \`false\` if the string doesn’t contain any vowel letter.
NOTE: Vowels are = a, e, o, u, i.
NOTE: Ignore upper/lower cases.

~~~js
Examples:
hasVowel("")   			    -> false
hasVowel("Javascript")   	-> true
hasVowel("Tech Global")   	-> true
hasVowel("1234")   		    -> false
hasVowel("ABC")   		    -> true
~~~`,
			`## Start Vowel
Write a function named as \`startVowel()\` which takes a \`string\` word as an argument and returns \`true\` if given string starts with a vowel, and \`false\` otherwise when invoked.
NOTE: Vowel letters: a, e, i o, u, A, E, I, O, U

~~~js
Examples:
startVowel("Hello")   	-> false
startVowel("Apple")   	-> true
startVowel("orange")    -> true
startVowel("")   	    -> false
startVowel("	")   	-> false
startVowel("123")   	-> false
~~~`,
			`## Average of Edges
Write a function named \`averageOfEdges()\` which takes \`three number arguments\` and will return \`average\` of min and max numbers​.

~~~js
Examples:
averageOfEdges(0, 0, 0)      -> 0
averageOfEdges(0, 0, 6)      -> 3
averageOfEdges(-2, -2, 10)   -> 4
averageOfEdges(-3, 15, -3)   -> 6
averageOfEdges(10, 13, 20)   -> 15
~~~`,
			`## Swap First and Last Characters
Write a function named \`replaceFirstLast()\` which takes a \`string\` argument and returns a new string with the first and last characters replaced.
NOTE: If the length is less than 2, return an empty string.
NOTE: Ignore extra spaces before and after the string.

~~~js
Examples:
replaceFirstLast("")   			    -> ""
replaceFirstLast("Hello")   		-> "oellH"
replaceFirstLast("Tech Global")   	-> "lech GlobaT"
replaceFirstLast("A")   		    -> ""
replaceFirstLast("    A      ")   	-> ""
~~~`,
			`## Swap First and Last Four Characters
Write a function named as \`swap4()\` which takes a \`string\` word as an argument and returns the string back with its first and last 4 characters swapped when invoked.
NOTE: Return empty string if the given string does not have 8 or more characters.

~~~js
Examples:
swap4("abc")   		    -> ""
swap4("JavaScript")   	-> "riptScJava"
swap4("TechGlobal")   	-> "obalGlTech"
swap4("")   			-> ""
swap4("	")   		    -> ""
swap4("Apple")   		-> ""
~~~`,
			`## Swap First and Last Words
Write a function named as \`swapFirstLastWord()\` which takes a \`string\` word as an argument and returns the string back with its first and last words swapped when invoked.
NOTE: Return empty string if the given string does not have 2 or more words.

~~~js
Examples:
swapFirstLastWord("Hello World")   		-> "World Hello"
swapFirstLastWord("I like JavaScript") 	-> "JavaScript like I"
swapFirstLastWord("foo bar foo bar")   	-> "bar bar foo foo"
swapFirstLastWord("")   			    -> ""
swapFirstLastWord("	")   			    -> ""
swapFirstLastWord("Hello")   			-> ""
swapFirstLastWord("Hello   ")   		-> ""
~~~`,
			`## Find Even Numbers
Write a function named as \`getEvens()\` which takes \`2 number arguments\` and returns all the even numbers as an array stored from smallest even number to greatest even number when invoked.
NOTE: Make your code dynamic that works for any numbers and return an empty array if there are no even numbers in the range of given 2 numbers.
Assume you will not be given negative numbers.

~~~js
Examples:
getEvens(2, 7)  	-> [ 2, 4, 6 ]
getEvens(17, 5)  	-> [ 6, 8, 10, 12, 14, 16 ]
getEvens(4, 4)  	-> [ 4 ]
getEvens(3, 3)  	-> [ ]
~~~`,
			`## Find Numbers Divisible By 5
Write a function named as \`getMultipleOf5()\` which takes \`2 number arguments\` and returns all the numbers divisible by 5 as an array stored from first found match to last found match when invoked.
NOTE: Make your code dynamic that works for any numbers and return an empty array if there are no numbers divisible by 5 in the range of given 2 numbers.
Assume you will not be given negative numbers.

~~~js
Examples:
getMultipleOf5(3, 17)  	-> [ 5, 10, 15]
getMultipleOf5(23, 5)  	-> [ 20, 15, 10, 5 ]
getMultipleOf5(5, 5)  	-> [ 5 ]
getMultipleOf5(2, 4)  	-> [ ]

~~~`,
			`## Count Positive Numbers
Write a function named \`countPos()\` which takes an \`array of numbers\` as an argument and returns how many elements are positive​ when invoked.

~~~js
Examples:
countPos([-45, 0, 0, 34, 5, 67])   	    -> 3
countPos([-23, -4, 0, 2, 5, 90, 123])   -> 4
countPos([0, -1, -2, -3])   		    -> 0
~~~`,
			`## Count Negative Numbers
Write a function named \`countNeg()\` which takes an \`array of numbers\` as an argument and returns how many elements are negative when invoked.

~~~js
Examples:
countNeg([-45, 0, 0, 34, 5, 67])   	    -> 1
countNeg([-23, -4, 0, 2, 5, 90, 123])   -> 2
countNeg([0, -1, -2, -3])   		    -> 3
~~~`,
			`## Count A
Write a function named \`countA()\` which takes a \`string\` argument and returns how many A or a there are in the given string when invoked.
NOTE: Ignore case sensitivity.

~~~js
Examples:
countA("TechGlobal is a QA bootcamp")   	-> 4
countA("QA stands for Quality Assurance")   -> 5
countA("Cypress")   					    -> 0
~~~`,
			`## Count Words
Write a function named \`countWords()\` which takes a \`string\` argument and returns the total count of words in the given string when invoked.
NOTE: Be careful about the extra whitespaces before and after the string.

~~~js
Examples:
countWords("     Javascript is fun       ")   		    -> 3
countWords("Cypress is an UI automation tool.    ")   	-> 6
countWords("1 2 3 4")   					            -> 4

~~~`,
			`## Factorial
Write a function named as factorial() which takes a number as an argument and returns the factorial of the number when invoked.
NOTE: Mathematically, the factorial of a non-negative integer n is defined as:
~~~js
n! = n * (n-1) * (n-2) * ... * 2 * 1
~~~
Assume you will not be given a negative number.

~~~js
Examples:
factorial(5)   	-> 120
factorial(4)  	-> 24
factorial(0)  	-> 1
factorial(1)  	-> 1
~~~`,
			`## Count 3 or Less
Write a function named \`count3OrLess()\` which takes a \`string\` word as an argument and returns the count of the words that have 3 characters or less when invoked.

~~~js
Examples:
count3OrLess("Hello")   			-> 0
count3OrLess("Hi John")   			-> 1
count3OrLess("JavaScript is fun")   -> 2
count3OrLess("My name is John Doe") -> 3
count3OrLess("")   				    -> 0
~~~`,
			`## Remove Extra Spaces
Write a function named as \`removeExtraSpaces()\` which takes a \`string\` word as an argument and returns the string back with all extra spaces removed when invoked.

~~~js
Examples:
removeExtraSpaces("Hello")   				        -> "Hello"
removeExtraSpaces("  	Hello    World 	")   		-> "Hello World"
removeExtraSpaces(" 	JavaScript is      	fun")   -> "JavaScript is fun"
removeExtraSpaces("")   					        -> ""
~~~`,
			`## Middle Number
Write a function named \`middleInt()\` which takes \`three number arguments\` and return the middle number. ​

~~~js
Examples:
middleInt(1, 2, 2)    -> 2
middleInt(5, 5, 8)    -> 5
middleInt(5, 3, 5)    -> 5
middleInt(1, 1, 1)    -> 1
middleInt(-1, 25, 10) -> 10
~~~`,
		],
	},
	{
		q17: [
			`
## First Duplicate Element
Write a function named as \`firstDuplicate()\` which takes an \`array argument\` and returns the first duplicated number in the array when invoked.
NOTE: Make your code dynamic that works for any array and return -1 if there are no duplicates in the array. For two elements to be considered as duplicated, value and data types of the elements must be same.

~~~js
Examples:
firstDuplicate([ 3, 7, 10, 0, 3, 10 ])  			-> 3
firstDuplicate([ 5, 7, 7, 0, 5, 10 ])  				-> 5
firstDuplicate([ 5, '5', 3, 7, 4 ])  				-> -1
firstDuplicate([ 123, 'abc', '123', 3, 'abc' ])  	-> 'abc'
firstDuplicate([ 1, 2, 3])  						-> -1
firstDuplicate([ 'foo', 'abc', '123', 'bar' ])   	-> -1
~~~`,
			`
## Find All Duplicate Elements
Write a function named as \`getDuplicates()\` which takes an array argument and returns all the duplicate elements in the array when invoked.
NOTE: Make your code dynamic that works for any array and return empty array if there are no duplicates in the array. For two elements to be considered as duplicated, value and data types of the elements must be the same.

~~~js
Examples:
getDuplicates([ 0, -4, -7, 0, 5, 10, 45, -7, 0 ])  					-> [ 0, -7 ]
getDuplicates([ 1, 2, 5, 0, 7 ])  									-> [ ]
getDuplicates(['A', 'foo', '12' , 12, 'bar', 'a', 'a', 'foo' ])  	-> [ 'foo', 'a' ]
getDuplicates([ 'foo', '12' , 12, 'bar', 'a' ]) 					-> [ ]
~~~`,
			`
## Reverse String Words
Write a function named \`reverseStringWords()\` which takes a \`string\` as an argument and returns the string back with each word separately reversed when invoked.
NOTE: Make your code dynamic that works for any string. Make sure you consider extra spaces before and after words in the given string.

~~~js
Examples:
reverseStringWords("Hello World")   		-> "olleH dlroW"
reverseStringWords("I like JavaScript")   	-> "I ekil tpircSavaJ"
reverseStringWords("Hello")   		        -> "olleH"
reverseStringWords("")   			        -> ""
reverseStringWords("	")   			    -> ""
~~~`,
			`
## FizzBuzz
Write a function named as fizzBuzz() which takes 2 number arguments and returns a string composed with below requirements when invoked.
 - You need to find all the numbers within the range of given 2 numbers (both inclusive) and store them in a string from smallest to greatest number with a ' | ' separator for each number.
 - You will need to convert numbers divisible by 3 to 'Fizz'
 - You will need to convert numbers divisible by 5 to 'Buzz'
 - You will need to convert numbers divisible by both 3 and 5 to 'FizzBuzz'
 - The rest will stay the same.

NOTE: Make your code dynamic that works for any numbers.
Assume you will not be given negative numbers.

~~~js
Examples:
fizzBuzz(13, 18)  	-> "13 | 14 | FizzBuzz | 16 | 17 | Fizz"
fizzBuzz(12, 5)  	-> "Buzz | Fizz | 7 | 8 | Fizz | Buzz | 11 | Fizz"
fizzBuzz(5, 5)  	-> "Buzz"
fizzBuzz(9, 6)  	-> "Fizz | 7 | 8 | Fizz"
~~~`,
			`
## Count Vowels
Write a function named as \`countVowels()\` which takes a \`string\` word as an argument and returns the count of the vowel letters when invoked.
NOTE: Vowel letters are A,E, O, U, I, a, e, o, u, i

~~~js
Examples:
countVowels("Hello")   		        -> 2
countVowels("JavaScript is fun")  	-> 5
countVowels("")   			        -> 0
~~~`,
			`
## Count Consonants
Write a function named as \`countConsonants()\` which takes a \`string\` word as an argument and returns the count of the consonant letters when invoked.
NOTE: A letter that is not vowel is considered as a consonant letter.

~~~js
Examples:
countConsonants("Hello")   			    -> 3
countConsonants("Hello World")   		-> 8
countConsonants("JavaScript is fun")   	-> 12
countConsonants("")   			        -> 0
~~~`,
			`
## Palindrome
Write a function named \`isPalindrome()\` which takes a \`string\` word as an argument and returns true if the word is palindrome or returns false otherwise when invoked.
NOTE: Palindrome: It is a word that is read the same backward as forward
Examples: kayak, civic, madam
NOTE: your function should ignore case sensitivity

~~~js
Examples:
isPalindrome("Hello")       -> false
isPalindrome("Kayak")       -> true
isPalindrome("civic")       -> true
isPalindrome("abba")        -> true
isPalindrome("ab  a")       -> false
isPalindrome("123454321")   -> true
isPalindrome("A")           -> true
isPalindrome("")            -> true
~~~`,
			`
## Prime Number
Write a function named as \`isPrime()\` which takes a \`number\` as an argument and returns true if the number is prime or returns false otherwise when invoked.
NOTE: Mathematically, the Prime number is a number that can be divided only by itself and 1. It cannot be divided by any other number. The smallest prime number is 2 and 2 is the only even prime number.
Examples: 2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31…
NOTE: The smallest prime number is 2 and there are no negative prime numbers.

~~~js
Examples:
isPrime(5)   -> true
isPrime(2)   -> true
isPrime(29)  -> true
isPrime(-5)  -> false
isPrime(0)   -> false
isPrime(1)   -> false
~~~`,
			`
## Count Multiple Words
Write a function named \`countMultipleWords()\` which takes an \`array\` as an argument and returns the count of the elements that have multiple words when invoked.
NOTE: Be careful about the extra whitespaces before and after the array element.

~~~js
Examples:
countMultipleWords([ "foo", "", "	", "foo bar", " 	foo" ])   	    -> 1
countMultipleWords([ "foo", "bar", "foobar", " 	foobar   " ])   	    -> 0
countMultipleWords([ "f o o", "b a r", "foo bar", "     foo bar   " ])  -> 4
countMultipleWords([ ])   						                        -> 0
~~~`,
			`
## Add Two Arrays
Write a function named \`add()\` which takes \`two array\` of numbers as argument and returns a new array with sum of given arrays elements.
NOTE: Be careful about the array sizes as they could be different.

~~~js
Examples:
add([3, 0, 0, 7, 5, 10], [6, 3, 2])   			       -> [9, 3, 2, 7, 5, 10]
add([10, 3, 6, 3, 2], [6, 8, 3, 0, 0, 7, 5, 10, 34])   -> [16, 11, 9,  3, 2, 7, 5, 10, 34]
add([-5, 6, -3, 11], [5, -6, 3, -11])   			   -> [0, 0, 0, 0]
~~~`,
			`
## Add Two Arrays
Write a function named \`noA()\` which takes an \`array of strings\` as argument and will return a new array with all elements starting with "A" or "a" replaced with "###".

~~~js
Examples:
noA(["javascript", "hello", "123", "xyz"])   ->  ["javascript", "hello", "123", "xyz"]
noA(["apple", "123", "ABC", "javascript"])   ->  ["###", "123", "###", "javascript"]
noA(["apple", "abc", "ABC", "Alex", "A"])    ->  ["###", "###", "###", "###", "###"]
~~~`,
			`
## No Elements Divisible By 3 and 5
Write a function named \`no3and5()\` which takes an \`array of integer numbers\` as argument and will return a new array with elements replaced by conditions below.
If element can be divided by 5, replace it with 99
If element can be divided by 3, replace it with 100
If element can be divided by both 3 and 5, replace it with 101

~~~js
Examples:
no3and5([7, 4, 11, 23, 17])   		-> [7, 4, 11, 23, 17]
no3and5([3, 4, 5, 6])   		    -> [100, 4, 99, 100]
no3and5([10, 11, 12, 13, 14, 15])   -> [99, 11, 100, 13, 14, 101]
~~~`,
			`
## No Elements Equals 13
Write a function named \`no13()\` which takes an \`array of numbers\` as argument and return a new array with all 13s replaced with 0s.

~~~js
Examples:
no13([1, 2, 3 ,4])   		 -> [1, 2, 3 ,4] 
no13([13, 2, 3])   		     -> [0, 2, 3]
no13([13, 13, 13 , 13, 13])  -> [0, 0, 0, 0, 0]
no13([])   			         -> []
~~~`,
			`
## Remove Duplicates
Write a function named \`removeDuplicates()\` which takes an \`array\` argument and returns a new array with all the duplicates removed.

~~~js
Examples:
removeDuplicates([10, 20, 35, 20, 35, 60, 70, 60])   		 -> [10, 20, 35, 60, 70]
removeDuplicates([1, 2, 5, 2, 3])   				         -> [1, 2, 5, 3]
removeDuplicates([0, -1, -2, -2, -1])   			         -> [0, -1, -2]
removeDuplicates(["abc", "xyz", "123", "ab", "abc", "ABC"])  -> ["abc", "xyz", "123", "ab", "ABC"]
removeDuplicates(["1", "2", "3", "2", "3"])   			     -> ["1", "2", "3"]
~~~`,
			`
## No Digits
Write a function named \`noDigit()\` which takes a \`string\` argument and returns a new string with all digits removed from the original string​.

~~~js
Examples:
noDigit("")   				    -> ""
noDigit("Javascript")   		-> "Javascript"
noDigit("123Hello")   			-> "Hello"
noDigit("123Hello World149")   	-> "Hello World"
noDigit("123Tech456Global149")  -> "TechGlobal"
~~~`,
			`
## No Vowel
Write a function named \`noVowel()\` which takes a \`string\` argument and returns a new string with all vowels removed from the original string​.

~~~js
Examples:
noVowel("TechGlobal")   -> "TchGlbl"
noVowel("AEOxyz")       -> "xyz"
noVowel("Javascript")   -> "Jvscrpt"
noVowel("")             -> ""
noVowel("125$")         -> "125$"
~~~`,
			`
## Sum of Digits
Write a function named \`sumOfDigits()\` which takes a \`string\` argument and returns sum of all digits from the original string. ​

~~~js
Examples:
sumOfDigits("Javascript")   		-> 0
sumOfDigits("John’s age is 29")   	-> 11
sumOfDigits("$125.0")   		    -> 8
sumOfDigits("")   			        -> 0
~~~`,
			`
## Array Factorial
Write a function named \`arrFactorial()\` which takes an \`array of numbers\` as argument and return the array with every number replaced with their factorials.

~~~js
Examples:
arrFactorial([1, 2, 3 ,4])  -> [1, 2, 6, 24]
arrFactorial([0, 5])   		-> [1,120]
arrFactorial([5 , 0, 6])   	-> [120, 1, 720]
arrFactorial([])   		    -> []
~~~`,
		],
	},
];

export const MOCK_QUESTIONS_3 = [
	{
		q1: [
			'Tell me about yourself.',
			'Tell me about your strengths and weaknesses and how they affect your daily job.',
			'How did you start your career in the Software Testing field?',
			'Where do you see yourself in 3-5 years?',
			'What motivates you to wake up 5 days and do this job? How do you motivate yourself?',
			'How do you keep your skills (technical and non-technical) sharp?',
			'What are some daily challenges you face in your current job?',
		],
	},
	{
		q2: [
			'Tell me about your Scrum Team and your responsibilities within the team.',
			'Tell me about Agile and how do you implement it in your company environment?',
			'What is a user story and test case? What are their differences?',
			'How would you describe a software bug and what do you do when you find a bug in the application you test?',
			'What do you do when you find a bug, but developer rejects it?',
			'When do you think testing should start in SDLC? (Which phase and why? What can you test in each phase of SDLC?)',
			'How do you decide if a test case should be manual or automated?',
			'How do you decide if you have enough test coverage for requirements (Acceptance Criteria) and what do you do when the requirements are not clear?',
		],
	},
	{
		q3: [
			'What is a Test Case and what are Test Case components?',
			'What is the Bug Life Cycle?',
			'What needs to be included in a Bug ticket?',
			'What is the difference between priority vs severity of a bug?',
			'What is Smoke Testing, Sanity Testing and Regression Testing and what are their differences?',
			'What is End-to-End Testing and what are the benefits it provides?',
			'What is User Acceptance Testing (UAT) and when to perform it?',
		],
	},
	{
		q4: [
			'What is Agile and Waterfall, and how do they differ?',
			'What are the Scrum ceremonies, artifacts, and roles?',
			'What is Software Testing and what are the different types of Software Testing?',
			'What is Unit Testing and Integration Testing and what are their differences?',
			'What is Performance Testing and why is it needed? What are different types of Performance Testing?',
			'What is A/B Testing and why is it needed?',
			'What is Monkey (Ad Hoc) Testing and what are the benefits it provides?',
			'What is User Story and Acceptance Criteria and who is responsible for creating and managing them?',
			'Can you explain the definition of "Ready" and definition of "Done"?',
			'What are differences between Test Scenario, Test Case and Test Script?',
		],
	},
	{
		q5: [
			'What is an SSH Key?',
			'What is GIT branching?',
			'What are some GIT commands you use?',
			'What is a Pull Request?',
			'What is a merge conflict and how to resolve it?',
		],
	},
	{
		q6: [
			"What are the differences between 'var', 'let', and 'const'?",
			'List and briefly describe the basic data types in JavaScript.',
			"What are the differences between '=' and '==' and '==='?",
			'What are lambda or arrow functions in JavaScript?',
			'What is a callback function and why do we need it? ',
			'What are the spread operator and rest parameters and differences between them?',
			'What is a constructor, why do we need it and how do we use it?',
			'What is this keyword and why do we use it in JavaScript?',
		],
	},
	{
		q7: [
			'What is immutable in JavaScript? Explain in detail.',
			'What is scope in JavaScript? Explain in detail.',
			'What is hoisting in JavaScript?',
			'What are closures in JavaScript?',
			'What are the different ways to deal with Asynchronous Code?',
			'What are modules and why do we need them?',
			'How does prototypal inheritance work in JavaScript?',
			'What are classes in ES6, how do you extend and why do we need it?',
		],
	},
	{
		q8: [
			'Explain the concept of union types in TypeScript.',
			'What is the "readonly" modifier in TypeScript, and when is it useful?',
			'How can you achieve type checking and auto-completion in editors for TypeScript?',
			'How do you define and use classes in TypeScript?',
			'How does TypeScript support asynchronous programming, and what are async/await?',
			'How does TypeScript handle modules, and what is the difference between "import" and "require"?',
			'How do you set up a TypeScript project with npm?',
			'What is the purpose of the "tsconfig.json" file in a TypeScript project?',
			'How can you integrate TypeScript with popular testing frameworks like Jasmine or Jest?',
			'Explain the role of generics in TypeScript and provide an example.',
			'Explain the concept of union types in TypeScript.',
			'What is the "readonly" modifier in TypeScript, and when is it useful?',
			'How can you achieve type checking and auto-completion in editors for TypeScript?',
			'How do you define and use classes in TypeScript?',
			'How does TypeScript support asynchronous programming, and what are async/await?',
			'How does TypeScript handle modules, and what is the difference between "import" and "require"?',
			'How do you set up a TypeScript project with npm?',
			'What is the purpose of the "tsconfig.json" file in a TypeScript project?',
			'How can you integrate TypeScript with popular testing frameworks like Jasmine or Jest?',
			'Explain the role of generics in TypeScript and provide an example.',
		],
	},
	{
		q9: [
			'What is Cypress and why would you choose it over other testing tools?',
			'Explain the architecture of Cypress.',
			'What is a Cypress Test Runner?',
			'What are the advantages of Cypress?',
			'What are the disadvantages and limitations of Cypress?',
			'How does Cypress handle asynchronous operations?',
			'What are the Cypress Bundled Libraries?',
			'Describe the structure of a typical Cypress test.',
			'How do you handle page navigation and redirection in Cypress tests?',
			'How do you validate the URL and title in Cypress?',
			'What are the different ways to locate elements with Cypress?',
		],
	},
	{
		q10: [
			'How to handle locating multiple elements with Cypress?',
			'How will you get the first as well as the last child of a chosen element?',
			'What are the different ways to interact with the elements in Cypress?',
			'What are the different types of Cypress assertions?',
			'How to handle iFrame in Cypress?',
			'How do you handle multiple windows in Cypress?',
			'How many kinds of alerts are you familiar with, and how do you manage these alerts using Cypress?',
			'What are ‘stub’ and ‘spy’ in Cypress?',
			'How to handle file Download and Upload in Cypress?',
			'How to see the default configurations in Cypress?',
		],
	},
	{
		q11: [
			'What are the custom commands in Cypress, and how many custom command types does Cypress have?',
			'How do you handle flaky (inconsistent) tests in Cypress?',
			'How do you maintain the test data in Cypress?',
			'What are the hooks in Cypress?',
			'How to skip or run specific tests in Cypress?',
			'How will you run a single spec file in the command line?',
			'How to run tests in headless mode in Cypress?',
			'How to overwrite any configuration in Cypress?',
			'Can you define an environment variable in Cypress?',
			'How to overwrite an environment variable in Cypress?',
			'How can I run my tests against specific browsers in Cypress?',
		],
	},
	{
		q12: [
			'What is shadow DOM, and how can you access it in Cypress?',
			'What Testing Framework does Cypress come with?',
			'Could you describe the Cypress default folder structures?',
			'How do you implement hard pause with Cypress?',
			'How to debug in Cypress?',
			'What is the fixture in Cypress?',
			'How can I change the baseUrl in Cypress dynamically?',
			'How can I click the hidden element?',
			'How do you handle timeouts in Cypress tests?',
			'In Cypress, does setting a timeout force a wait for its full duration even if conditions are met earlier?',
			'How can you change the location of your default folder structure?',
			'How can you exclude specific test files from your test run?',
			'How can I run the specific tests/test suites only on specific browsers without doing configuration file Changes?',
			'How can I use JavaScript methods with Cypress commands?',
			'How can I introduce JavaScript objects to Cypress?',
			'How can you chain multiple assertions in Cypress?',
			'How can I handle exceptions in Cypress?',
		],
	},
	{
		q13: [
			'What is Playwright and why would you choose it over other testing tools ?',
			'What are the Playwright core features/advantages and disadvantages?',
			'How does Playwright handle asynchronous operations ?',
			'How do you handle page navigation and redirection in Playwright ?',
			'How do you validate the URL and title in Playwright ?',
			'What are the different ways to locate elements with Playwright ?',
			'How to handle locating multiple elements with Playwright?',
			'What are the different ways to interact with the elements in Playwright ?',
			'How to access web elements properties in Playwright ? ',
			'Does Playwright support geolocation?',
			'What are different types of assertions in Playwright?',
			'What are soft assertions in Playwright?',
			'Difference between page.on() and page.once()?',
			'How do you handle mouse, and keyboard actions in Playwright ?',
			'What are timeouts in Playwright? What are the different types of Timeouts available in Playwright ?',
			'How many types of waits do you know in Playwright ?',
		],
	},
	{
		q14: [
			'How to handle iFrames in Playwright ?',
			'What is browser context in Playwright ?',
			'How to open multiple tabs with Playwright ?',
			'How do you handle navigation that triggers link/popup in Playwright ?',
			'How do you handle alerts/dialogs in Playwright ?',
			'How do you handle File Download and Upload in Playwright ?',
			'How to ignore specific a test, or test files in Playwright ?',
			'What is playwright.config file, can you explain in detail ?',
			'How can you filter, or group tests based on their types ?',
			'What are the "hooks" in a playwright ?',
			'What are the test Annotations in playwright ?',
			'How to run tests "serial" mode in Playwright ?',
			'What is testInfo Object ?',
			'Tell me about Playwright Command Lines.',
		],
	},
	{
		q15: [
			'How to evaluate JavaScript code in Playwright ?',
			'What is actionability in Playwright ? Explain in detail.',
			'What are the different types of reporters that the playwright supports ?',
			'What are the different ways to debug your tests in Playwright ?',
			'What are some common errors you might encounter when using Playwright?',
			'How to capture screenshots in Playwright ?',
			'What is Visual Regression(snapshot) testing, and how to perform in Playwright ?',
			'What are test Fixtures in Playwright ?',
			'What is Global Setup and Teardown in Playwright ?',
			'Are you familiar with storageState in Playwright, can you explain it ?',
			'What is Accessibility Testing and how to perform it in Playwright ?',
			'How to perform parallel execution in Playwright ?',
			'How does Playwright handle flaky tests or intermittent failures ?',
		],
	},
	{
		q16: [
			'What is Node.js and why is it used?',
			'How to run Node.js from the command line?',
			'What is npm?',
			'How to check your Node or npm version?',
			'What is the role of package.json in a JavaScript project?',
			'Can you explain what the node_modules directory is used for in Node.js?',
			'What does the npm install command do?',
			'What is npx and how is it used in JavaScript projects?',
			'How to Initialize a Node.js Project?',
			'How to install packages in devDependency?',
			'How to install the specific or latest version of a dependency?',
		],
	},
	{
		q17: [
			'What is Cucumber?',
			'What language is used by Cucumber?',
			'What are the various keywords that are used in Cucumber for writing a scenario?',
			'What is the use of behavior-driven development in Agile methodology?',
			'What are the major advantages of the Cucumber framework?',
			'What is a feature file?',
			'What are the files required to execute a Cucumber test scenario?',
			'What is the Step Definition in Cucumber?',
			'What is the starting point of execution for feature files?',
		],
	},
	{
		q18: [
			'When do you use the Background keyword in Cucumber?',
			'What is a Scenario in Cucumber?',
			'What is a Scenario Outline in Cucumber?',
			'What is the purpose of the Examples keyword in Cucumber?',
			'What is a tag in Cucumber?',
			'What is the use of the dryRun property under the @CucumberOptions?',
			'What is the difference between the Examples table and the Data table?',
			'What are the Hooks in Cucumber?',
		],
	},
	{
		q19: [
			`
## Swap First and Last Characters
Write a function named \`replaceFirstLast()\` which takes a \`string\` argument and returns a new \`string\` with the first and last characters replaced.
NOTE: If the length is less than 2, return an empty string.
NOTE: Ignore extra spaces before and after the string.

~~~js
Examples:
replaceFirstLast("")                    ->  ""
replaceFirstLast("Hello")               ->  "oellH"
replaceFirstLast("Tech Global")     	-> "lech GlobaT"
replaceFirstLast("A")                   -> ""
replaceFirstLast("    A      ")         -> ""
~~~`,
			`
## Swap First and Last Words
Write a function named \`swapFirstLastWord()\` which takes a \`string\` word as an argument and returns the \`string\` back with its first and last words swapped when invoked.
NOTE: Return empty string if the given string does not have 2 or more words.

~~~js
Examples:
swapFirstLastWord("Hello World")             	-> "World Hello"
swapFirstLastWord("I like JavaScript")      	-> "JavaScript like I"
swapFirstLastWord("foo bar foo bar")        	-> "bar bar foo foo"
swapFirstLastWord("")                           -> ""
swapFirstLastWord("  ")                         -> ""
swapFirstLastWord("Hello")                      -> ""
swapFirstLastWord("Hello   ")                   -> ""
~~~`,
			`
## Factorial
Write a function named as \`factorial()\` which takes a \`number\` as an argument and returns the factorial of the number when invoked.
NOTE: Mathematically, the factorial of a non-negative integer n is defined as: n! = n × (n-1) × (n-2) × ... × 2 × 1
Assume you will not be given a negative number.

~~~js
Examples:
factorial(5)   	-> 120
factorial(4)   	-> 24
factorial(0)   	-> 1
factorial(1)   	-> 1
~~~`,
			`
## Remove Extra Spaces
Write a function named as \`removeExtraSpaces()\` which takes a \`string\` word as an argument and returns the \`string\` back with all extra spaces removed when invoked.

~~~js
Examples:
removeExtraSpaces("Hello")                                      -> "Hello"
removeExtraSpaces("        	Hello    World ")                 	-> "Hello World"
removeExtraSpaces("        	JavaScript is           	fun")   -> "JavaScript is fun”
removeExtraSpaces("")                                           -> "" 
~~~`,
			`
## First Duplicate Element
Write a function named as \`firstDuplicate()\` which takes an \`array\` argument and returns the first duplicated number in the array when invoked.
NOTE: Make your code dynamic that works for any array and return -1 if there are no duplicates in the array. For two elements to be considered as duplicated, value and data types of the elements must be the same.

~~~js
firstDuplicate([ 3, 7, 10, 0, 3, 10 ])              	-> 3
firstDuplicate([ 5, 7, 7, 0, 5, 10 ])                	-> 5
firstDuplicate([ 5, '5', 3, 7, 4 ])                     -> -1
firstDuplicate([ 123, 'abc', '123', 3, 'abc' ])  	    -> 'abc'
firstDuplicate([ 1, 2, 3])                              -> -1
firstDuplicate([ 'foo', 'abc', '123', 'bar' ])      	-> -1

~~~`,
			`
## Find All Duplicate Elements
Write a function named as \`getDuplicates()\` which takes an \`array\` argument and returns all the elements duplicated in the array when invoked.
NOTE: Make your code dynamic that works for any array and return an empty array if there are no duplicates in the array. For two elements to be considered as duplicated, value and data types of the elements must be the same.

~~~js
Examples:
getDuplicates([ 0, -4, -7, 0, 5, 10, 45, -7, 0 ])                     	-> [ 0, -7 ]
getDuplicates([ 1, 2, 5, 0, 7 ])                                        -> [ ]
getDuplicates(['A', 'foo', '12' , 12, 'bar', 'a', 'a', 'foo' ])         -> [ 'foo', 'a' ]
getDuplicates([ 'foo', '12' , 12, 'bar', 'a' ])                         -> [ ]
~~~`,
			`
## Reverse String Words
Write a function named as \`reverseStringWords()\` which takes a \`string\` as an argument and returns the string back with each word separately reversed when invoked.
NOTE: Make your code dynamic that works for any string. Make sure you consider extra spaces before and after words in the given string.

~~~js
Examples:
reverseStringWords("Hello World")            	-> "olleH dlroW"
reverseStringWords("I like JavaScript")     	-> "I ekil tpircSavaJ"
reverseStringWords("Hello")                     -> "olleH"
reverseStringWords("")                          -> ""
reverseStringWords(" ")                         -> ""
~~~`,
			`
## Add Two Arrays
Write a function named \`add()\` which takes \`two array\` of numbers as arguments and returns a \`new array\` with the sum of given array elements.
NOTE: Be careful about the array sizes as they could be different.

~~~js
Examples:
add([3, 0, 0, 7, 5, 10], [6, 3, 2])                         -> [9, 3, 2, 7, 5, 10]
add([10, 3, 6, 3, 2], [6, 8, 3, 0, 0, 7, 5, 10, 34])      	-> [16, 11, 9,  3, 2, 7, 5, 10, 34]
add([-5, 6, -3, 11], [5, -6, 3, -11])                       -> [0, 0, 0, 0]
~~~`,
			`
## Fizz Buzz
Write a function named \`fizzBuzz()\` which takes a \`number\` argument and returns an array consisting of numbers starting from
1 to the given number. However, it will return "Fizz"
for the numbers divided by 3, "Buzz" for the numbers divided by 5, and "FizzBuzz" for the numbers divided both by 3 and 5.

~~~js
Examples:
fizzBuzz(3)  	-> [ 1, 2, 'Fizz' ]
fizzBuzz(5)  	-> [ 1, 2, 'Fizz', 4, 'Buzz' ]
fizzBuzz(10)	-> [ 1, 2, 'Fizz', 4, 'Buzz', 'Fizz', 7, 8, 'Fizz', 'Buzz' ]
fizzBuzz(15)	-> [ 1, 2, 'Fizz', 4, 'Buzz', 'Fizz', 7, 8, 'Fizz', 'Buzz', 11, 'Fizz', 13, 14,  'FizzBuzz' ]
fizzBuzz(2)  	-> [ 1, 2 ]
~~~`,
			`
## Palindrome
Write a function named \`isPalindrome()\` which takes a \`string\` word as an argument and returns \`true\` if the word is palindrome or returns \`false\` otherwise when invoked.
NOTE: Palindrome: It is a word that is read the same backward as forward
Examples: kayak, civic, madam
NOTE: your function should ignore case sensitivity

~~~js
Examples:
isPalindrome("Hello")   	-> false
isPalindrome("Kayak")   	-> true
isPalindrome("civic")   	-> true
isPalindrome("abba")   		-> true
isPalindrome("ab  a")   	-> false
isPalindrome("123454321")   -> true
isPalindrome("A")   		-> true
isPalindrome("")   			-> true
~~~`,
			`
## Remove Duplicates
Write a function named \`removeDuplicates()\` which takes an \`array\` argument and returns a new array with all the duplicates removed.

~~~js
Examples:
removeDuplicates([10, 20, 35, 20, 35, 60, 70, 60])           	-> [10, 20, 35, 60, 70]
removeDuplicates([1, 2, 5, 2, 3])                               -> [1, 2, 5, 3]
removeDuplicates([0, -1, -2, -2, -1])                       	-> [0, -1, -2]
removeDuplicates(["abc", "xyz", "123", "ab", "abc", "ABC"]) 	-> ["abc", "xyz", "123", "ab", "ABC"]
removeDuplicates(["1", "2", "3", "2", "3"])                     -> ["1", "2", "3"]
~~~`,
			`
## Prime Number
Write a function named as \`isPrime()\` which takes a \`number\` as an argument and returns \`true\` if the number is prime or returns \`false\` otherwise when invoked.
NOTE: Mathematically, the Prime number is a number that can be divided only by itself and 1. It cannot be divided by any other number. The smallest prime number is 2 and 2 is the only even prime number.
Examples: 2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31…
NOTE: The smallest prime number is 2 and there are no negative prime numbers.

~~~js
Examples:
isPrime(5)   -> true
isPrime(2)   -> true
isPrime(29)  -> true
isPrime(-5)  -> false
isPrime(0)   -> false
isPrime(1)   -> false
~~~`,
			`
## Array Factorial
Write a function named \`arrFactorial()\` which takes an \`array\` of numbers as argument and return the \`array\` with every number replaced with their factorials.

~~~js
Examples:
arrFactorial([1, 2, 3 ,4])       	-> [1, 2, 6, 24]
arrFactorial([0, 5])               	-> [1,120]
arrFactorial([5 , 0, 6])          	-> [120, 1, 720]
arrFactorial([])                    -> []
~~~`,
			`
## Find Biggest Number
Write a function named \`findBiggestNumber()\` which takes a \`string\` argument and returns the biggest number that appears in the string.

~~~js
Examples:
findBiggestNumber("abc$")	        	    -> 0
findBiggestNumber("a1b4c 6#")    	        -> 6
findBiggestNumber("ab110c045d")	        	-> 110
findBiggestNumber("525")  	        	    -> 525
findBiggestNumber("3for 10 dollars")        -> 10
~~~`,
			`
## Find Sum Numbers
Write a function named \`findSumNumbers()\` which takes a \`string\` argument and returns the sum of all numbers appearing in the string.

~~~js
Examples:
findSumNumbers("abc$")   	        	    -> 0
findSumNumbers("a1b4c 6#")       	        -> 11
findSumNumbers("ab110c045d")   	        	-> 155
findSumNumbers("525")     	        	    -> 525
findSumNumbers("3 for 10 dollars")          -> 13
~~~`,
			`
## Is Power Of 3
Write a function named \`isPowerOf3()\` which takes a \`number\` argument and returns \`true\` if the given number is equal to 3 power of the X. Otherwise, return \`false\`.
NOTE: Numbers that are powers of 3 = 1, 3, 9, 27, 81, 243….
NOTE: Ignore negative scenarios.

~~~js
Examples:
isPowerOf3(1)        	-> true
isPowerOf3(2)        	-> false
isPowerOf3(3)        	-> true
isPowerOf3(27)      	-> true
isPowerOf3(100)    	    -> false
isPowerOf3(81)      	-> true
isPowerOf3(9)        	-> true
~~~`,
			`
## Fibonacci Series 1
Write a function named \`fibonacciSeries1()\` which takes a \`number\` n argument and returns the n series of Fibonacci numbers as an array.
REMEMBER: Fibonacci series = 0, 1, 1, 2, 3, 5, 8, 13, 21

~~~js
Examples:
fibonacciSeries1(3) 	        	-> [0, 1, 1]
fibonacciSeries1(5) 	        	-> [0, 1, 1, 2, 3]
fibonacciSeries1(7) 	        	-> [0, 1, 1, 2, 3, 5, 8]
fibonacciSeries1(8) 	        	-> [0, 1, 1, 2, 3, 5, 8, 13]
fibonacciSeries1(1) 	        	-> [0]
fibonacciSeries1(2) 	        	-> [0, 1]
~~~`,
			`
## Fibonacci Series 2
Write a function named \`fibonacciSeries2()\` which takes a \`number\` n argument and returns the nth series of Fibonacci numbers as a number.
REMEMBER: Fibonacci series = 0, 1, 1, 2, 3, 5, 8, 13, 21

~~~js
Examples:
fibonacciSeries2(2) 	    -> 1
fibonacciSeries2(4) 	    -> 2
fibonacciSeries2(8) 	    -> 13
fibonacciSeries2(9) 	    -> 21
fibonacciSeries2(1) 	    -> 0
~~~`,
			`
## Find Uniques
Write a function named \`findUniques()\` which takes \`two array\` of number arguments and returns the array which has only the unique values from both given arrays.
NOTE: If both arrays are empty, then return an empty array.
NOTE: If one of the array is empty, then return unique values from the other array.
 
~~~js
Examples:
findUniques([], [])    	        	        -> []
findUniques([], [1, 2, 3, 2])  	        	-> [1, 2, 3]
findUniques([1, 2, 3, 4], [3, 4, 5, 5])	    -> [1, 2, 5]
findUniques([8, 9], [9, 8, 9])	        	-> []
findUniques([-1, -2], [1, 2]) 	        	-> [-1, -2, 1, 2]
~~~
`,

			`
## Reverse Number
Write a function named \`reverseNumber()\` which takes a \`number\` argument and returns it back reversed without converting it to a String.
NOTE: Do not convert numbers to string to complete the task.

~~~js
Examples:
reverseNumber(371)		-> 173
reverseNumber(123)		-> 321
reverseNumber(12) 		-> 21
reverseNumber(0)		-> 0
reverseNumber(111) 		-> 111
 ~~~
 `,
			`
## Is Armstrong
Write a function named \`isArmstrong()\` which takes a \`number\` argument and returns \`true\` if given number is armstrong, return \`false\` otherwise.
NOTE: An armstrong number is a number that is equal to the sum of its own digits raised to the power of the number of digits. In other words, an n-digit 
number is an Armstrong number if the sum of its digits, each raised to the nth power, is equal to the number itself.
- Let's take an example to understand it better. Consider the number 153.
- To determine if 153 is an armstrong number, we need to check if the sum of its digits, each raised to the power of the number of digits, equals the original number.
- 1^3 + 5^3 + 3^3 = 1 + 125 + 27 = 153
- In this case, the sum of the individual digits raised to the power of 3 (the number of digits in 153) is equal to the original number, which means 153 is an armstrong number.

 ~~~js
Examples:
isArmstrong(153) 	-> true
isArmstrong(123)	-> false
isArmstrong(1634)	-> true
isArmstrong(153) 	-> true
isArmstrong(1111) 	-> false
~~~
`,
			`
## Is Anagram
Write a function named \`isAnagram()\` which takes \`two string\` arguments and returns \`true\` if the given strings are anagram. Return \`false\` otherwise.
NOTE: An anagram is a word or phrase formed by rearranging the letters of another word or phrase. In the context of strings, checking if two strings are anagrams of each other means verifying if they contain the same characters in the same quantities, regardless of the order of those characters.
NOTE: This method is case-insensitive and ignore the white spaces.

~~~js
Examples:
isAnagram("Apple", "Peach")				-> false
isAnagram("listen", "silent")			-> true
isAnagram("astronomer", "moon starer")  -> true
isAnagram("CINEMA", "iceman")			-> true
isAnagram("123", "1234")				-> false
~~~
`,
			` 
## Count Palindrome
Write a function named \`countPalindrome()\` which takes a \`string\` and returns the number of  palindrome words.
Note: A palindrome word is a word that reads the same forwards and backwards. Example: level, radar, deed, refer.

~~~js
Examples:
countPalindrome("Mom and Dad")								-> 2
countPalindrome("See you at noon")							-> 1
countPalindrome("Kayak races attracts racecar drivers")		-> 2
countPalindrome("")											-> 0
countPalindrome("No palindrome here")						-> 0
~~~
`,
			`
## Can Form String
Write a function named \`canFormString()\` which takes \`two string\` arguments and returns true if the second string can be formed by rearranging the characters of first string. Return false otherwise.
NOTE: This method is case-insensitive and ignore the white spaces.

~~~js
Examples:
canFormString("Hello", "Hi")						-> false
canFormString("programming", "gaming")				-> true
canFormString("halogen", "hello")					-> false
canFormString("CONVERSATION", "voices rant on")	 	-> true
canFormString("12", "123")							-> false
~~~
`,
			`
## Count Occurrence
Write a function named \`countOccurrence()\` which takes \`two string\` arguments and returns how many times that the first string can form the second string.
NOTE: This method is case-insensitive and ignore the white spaces.

~~~js
Examples:
countOccurrence("Javascript", "Java")		-> 1
countOccurrence("Hello", "World")			-> 0
countOccurrence("Can I can a can", "anc")	-> 3
countOccurrence("Hello", "l")				-> 2
countOccurrence("IT conversations", "IT")	-> 2
~~~
`,
		],
	},
	{
		q20: [
			`
Write your code to automate below test case: Valid Login

1. Navigate to https://techglobal-training.com/frontend/project-2
2. Enter the username as “TechGlobal”
3. Enter the password as “Test1234”
4. Click on the “LOGIN” button

![login form](${loginFormImg1})
`,
			`
Write your code to automate below test case: Invalid login with the wrong username

1. Navigate to https://techglobal-training.com/frontend/project-2
2. Enter the username as "Tech"
3. Enter the password as "Test1234"
4. Click on the "LOGIN" button
5. Validate if the failure message displayed as "Invalid Username entered!" above the form

![login form](${loginFormImg2})
`,
			`
Write your code to automate below test case: Invalid login with the wrong password

1. Navigate to https://techglobal-training.com/frontend/project-2
2. Enter the username as "TechGlobal"
3. Enter the password as "1234"
4. Click on the "LOGIN" button
5. Validate if the failure message displayed as "Invalid Password entered!" above the form

![login form](${loginFormImg3})
`,
			`
Write your code to automate below test case: Main content

1. Navigate to https://techglobal-training.com/frontend/project-5
2. Validate the main heading is displayed as "Pagination"
3. Validate the subheading is displayed as "World City Populations 2022"
4. Validate the paragraph is displayed as "What are the most populated cities in the world? Here is a list of the top five most populated cities in the world:"

![pagination](${paginationImg})
`,
		],
	},
];

export const MOCK_QUESTIONS_4 = [
	{
		q1: [
			'What are the different data types in JavaScript?',
			'How do you check the type of a variable in JavaScript?',
			'Explain the concept of type coercion.',
			'Explain the difference between null and undefined.',
			'Explain the difference between var, let, and const.',
			'Describe the difference between == and === operators.',
			'What are the truthy/falsy values in JavaScript?',
			'What is the difference between nullish coalescing operator (??) and OR (||) operator?',
			'What is the ternary operator in JavaScript?',
			'What is the switch statement in JavaScript?',
			'Describe the differences between function declarations, function expressions and arrow functions.',
			'What are lambda or arrow functions, and how do they differ from regular functions?',
			'What is IIFE(Immediately Invoked Function Expression)?',
			'Explain the difference between map, filter, and reduce functions and provide examples.',
			'Explain the differences between Object.keys(), Object.values(), and Object.entries().',
			'Describe the differences between setTimeout() and setInterval().',
			'Explain the destructuring assignment in JavaScript.',
			'What are template literals in JavaScript?',
			'What are the spread operator and rest parameters and differences between them?',
		],
	},
	{
		q2: [
			'Explain hoisting in JavaScript.',
			'Explain the concept of closures in JavaScript.',
			'What is immutable in JavaScript? Explain in detail.',
			'Explain the scope concept in JavaScript.',
			'Explain the concept of higher-order functions and provide examples.',
			'Explain the differences between callbacks, promises, and async/await.',
			'What is callback hell?',
			'How do you handle/log errors in JavaScript, and what is the purpose of the try, catch, and finally blocks?',
			'What are the new features introduced in ES6 (ECMAScript 2015)?',
		],
	},
	{
		q3: [
			'What is a constructor, why do we need it and how do we use it?',
			'Explain what this keyword refers to in JavaScript.',
			'How does prototypal inheritance work in JavaScript?',
			'Explain the concept of object prototypes in JavaScript.',
			'What are classes in ES6, how do you extend and why do we need it?',
			'What is a super keyword and why do we use it in JavaScript?',
			'What are static properties and methods in JavaScript?',
			'Explain the concept of modules in modern JavaScript.',
			'Explain the differences between ES6 modules and CommonJS modules',
		],
	},
	{
		q4: [
			'How do you write a basic unit test for a JavaScript function?',
			'What are bind, call and apply methods?',
			'What is the currying function in JavaScript?',
			"Explain what Node.js is and why it's important for JavaScript?",
			'Explain what asynchronous code is and how it differs from synchronous code.',
			'Explain the event loop in JavaScript.',
			'Explain the concept of call-stack in JavaScript.',
			'Describe how you would debug a JavaScript application.',
			'Explain recursion.',
			'Explain Map and Set in JavaScript.',
			'Explain Stack and Queue in JavaScript.',
			'What is Big O Notation?',
			'What is JSON, why do we need it and what are its common operations?',
			'What is serialization and deserialization?',
			'Explain the purpose and usage of the use strict directive in JavaScript.',
			'Explain the concept of shallow copy and deep copy in JavaScript.',
			'What is the Document Object Model (DOM)?',
			'Explain the concept of the fetch API.',
		],
	},
	{
		q5: [
			'What is TypeScript, and how does it differ from JavaScript?',
			'Explain the benefits of using TypeScript in a testing environment.',
			'What are different common data types in TypeScript?',
			'How do you define a variable with a specific type in TypeScript?',
			"What is the 'any' type in TypeScript, and when should it be used cautiously?",
			"What is the 'never' type in TypeScript?",
			'What is type assertion in TypeScript?',
			'What are interfaces in TypeScript, and how are they useful in testing?',
			'What are the differences between Types (Type Aliases) and Interfaces?',
		],
	},
	{
		q6: [
			'Explain the concept of union types in TypeScript.',
			'What is the "readonly" modifier in TypeScript, and when is it useful?',
			'How can you achieve type checking and auto-completion in editors for TypeScript?',
			'How do you define and use classes in TypeScript?',
			'How does TypeScript support asynchronous programming, and what are async/await?',
			'How does TypeScript handle modules, and what is the difference between "import" and "require"?',
			'How do you set up a TypeScript project with npm?',
			'What is the purpose of the "tsconfig.json" file in a TypeScript project?',
			'How can you integrate TypeScript with popular testing frameworks like Jasmine or Jest?',
			'Explain the role of generics in TypeScript and provide an example.',
		],
	},
	{
		q7: [
			'​​What are linux commands and can you give some examples that you use?',
			'What is GIT branching?',
			'What are some GIT commands you use?',
			'What is a Pull Request?',
			'What is a merge conflict and how to resolve it?',
		],
	},
	{
		q8: [
			'Tell me about your experience with Jenkins.',
			'Have you ever set up Jenkins pipelines or Jenkins Jobs? Tell me details about it.',
			'Tell me the way you configured your Smoke, Regression or other jobs in Jenkins.',
		],
	},
	{
		q9: [
			'What is Cypress and why would you choose it over other testing tools?',
			'What are the advantages and disadvantages of Cypress?',
			'How does Cypress handle asynchronous operations?',
			'Describe the structure of a typical Cypress test.',
			'How do you handle page navigation and redirection in Cypress tests?',
			'How do you validate the URL and title in Cypress?',
			'What are the different ways to locate elements with Cypress?',
			'How to handle locating multiple elements with Cypress?',
			'How will you get the first as well as the last child of a chosen element?',
			'What are the different ways to interact with the elements in Cypress?',
			'What Testing Framework does Cypress come with?',
			'How to debug in Cypress?',
		],
	},
	{
		q10: [
			'What are the different types of Cypress assertions?',
			'How to handle iFrame in Cypress?',
			'How do you handle multiple windows in Cypress?',
			'How many kinds of alerts are you familiar with, and how do you manage these alerts using Cypress?',
			'What are ‘stub’ and ‘spy’ in Cypress?',
			'How to handle file Download and Upload in Cypress?',
			'What are the custom commands in Cypress, and how many custom command types does Cypress have?',
			'How do you handle flaky (inconsistent) tests in Cypress?',
			'How do you maintain the test data in Cypress?',
			'What are the hooks in Cypress?',
			'How to skip or run specific tests in Cypress?',
			'How will you run a single spec file in the command line?',
			'How to run tests in headless mode in Cypress?',
			'How do you implement hard pause with Cypress?',
		],
	},
	{
		q11: [
			'How to overwrite any configuration in Cypress?',
			'How to overwrite an environment variable in Cypress?',
			'How can I run my tests against specific browsers in Cypress?',
			'What is shadow DOM, and how can you access it in Cypress?',
			'Could you describe the Cypress default folder structures?',
			'What is the fixture in Cypress?',
			'How can I change the baseUrl in Cypress dynamically?',
			'How can I click the hidden element?',
			'How do you handle timeouts in Cypress tests?',
			'In Cypress, does setting a timeout force a wait for its full duration even if conditions are met earlier?',
			'How can you exclude specific test files from your test run?',
			'How can I run the specific tests/test suites only on specific browsers without doing configuration file Changes?',
			'How can I use JavaScript methods with Cypress commands?',
			'How can I introduce JavaScript objects to Cypress?',
			'How can you chain multiple assertions in Cypress?',
			'How can I handle exceptions in Cypress?',
		],
	},
	{
		q12: [
			'When do you use the Background keyword in Cucumber?',
			'What is a Scenario in Cucumber?',
			'What is a Scenario Outline in Cucumber?',
			'What is the purpose of the Examples keyword in Cucumber?',
			'What is a tag in Cucumber?',
			'What is the use of the dryRun property under the @CucumberOptions?',
			'What is the difference between the Examples table and the Data table?',
			'What are the Hooks in Cucumber?',
		],
	},
	{
		q13: [
			'What is Playwright and why would you choose it over other testing tools ?',
			'What are the Playwright core features/advantages and disadvantages?',
			'How does Playwright handle asynchronous operations ?',
			'How do you handle page navigation and redirection in Playwright ?',
			'How do you validate the URL and title in Playwright ?',
			'What are the different ways to locate elements with Playwright ?',
			'How to handle locating multiple elements with Playwright?',
			'What are the different ways to interact with the elements in Playwright ?',
			'How to access web elements properties in Playwright ? ',
			'Does Playwright support geolocation?',
		],
	},
	{
		q14: [
			'What are different types of assertions in Playwright?',
			'What are soft assertions in Playwright?',
			'Difference between page.on() and page.once()?',
			'How do you handle mouse, and keyboard actions in Playwright ?',
			'What are timeouts in Playwright? What are the different types of Timeouts available in Playwright ?',
			'How many types of waits do you know in Playwright ?',
		],
	},
	{
		q15: [
			'How to handle iFrames in Playwright ?',
			'What is browser context in Playwright ?',
			'How to open multiple tabs with Playwright ?',
			'How do you handle navigation that triggers link/popup in Playwright ?',
			'How do you handle alerts/dialogs in Playwright ?',
			'How do you handle File Download and Upload in Playwright ?',
		],
	},
	{
		q16: [
			'How to ignore specific a test, or test files in Playwright ?',
			'What is playwright.config file, can you explain in detail ?',
			'How can you filter, or group tests based on their types ?',
			'What are the "hooks" in a playwright ?',
			'What are the test Annotations in playwright ?',
			'How to run tests "serial" mode in Playwright ?',
			'What is testInfo Object ?',
			'Tell me about Playwright Command Lines.',
		],
	},
	{
		q17: [
			'How to evaluate JavaScript code in Playwright ?',
			'What is actionability in Playwright ? Explain in detail.',
			'What are the different types of reporters that the playwright supports ?',
			'What are the different ways to debug your tests in Playwright ?',
			'What are some common errors you might encounter when using Playwright?',
			'How to capture screenshots in Playwright ?',
			'What is Visual Regression(snapshot) testing, and how to perform in Playwright ?',
			'What are test Fixtures in Playwright ?',
			'What is Global Setup and Teardown in Playwright ?',
			'Are you familiar with storageState in Playwright, can you explain it ?',
			'What is Accessibility Testing and how to perform it in Playwright ?',
			'How to perform parallel execution in Playwright ?',
			'How does Playwright handle flaky tests or intermittent failures ?',
		],
	},
	{
		q18: [
			'What are the differences between Relational (SQL) and Non-Relational (NoSQL) databases? ',
			'What are the differences between primary key and foreign key in a table?',
			'What is a join and what kind of joins are you familiar with?',
			'How do you connect the Database with JavaScript and Cypress?',
		],
	},
	{
		q19: [
			'How do you get only unique values from the table using the SELECT query?',
			'How do you order the results of a query?',
			'What is the difference between AND, OR, and NOT operators?',
			'What is the difference between BETWEEN and IN operators?',
			'When do you use the GROUP BY?',
			'What is the difference between the WHERE and HAVING clauses?',
			'What is the difference between DELETE, TRUNCATE and DROP?',
		],
	},
	{
		q20: [
			'What are the Single Row Functions?',
			'What are the Multi-Row Functions?',
			'How do you use the subquery?',
			'What are the SET Operators?',
			'What are DDL, DQL, DML, and DCL?',
			'How do you add a record to a table?',
			'How do you add a column to a table?',
		],
	},
	{
		q21: [
			'Can you explain the difference between REST and SOAP APIs?',
			'What are the main components of an HTTP request and response?',
			'What does CRUD stand for, and what are the CRUD methods ?',
			'What are the different types of HTTP status codes? Can you provide an example of when each might be used?',
		],
	},
	{
		q22: [
			'Can you explain what a payload is in API testing?',
			'Explain the concept of API endpoints and their significance.',
			'What is the importance of headers in API requests and responses?',
			'Explain the difference between PUT and PATCH methods.',
			'Difference between Authentication and Authorization?',
			'How do you validate response body and status codes in API testing?',
			'What are Postman Collections and how do you use them?',
			'How do you use environments and variables in Postman?',
			'How many variable types are you familiar with in Postman?',
		],
	},
	{
		q23: [
			'How does OAuth work? Can you explain a scenario where it might be used in API testing?',
			'What tools or libraries have you used for API testing? Can you compare them in terms of ease of use, functionality, and scalability?',
			'What is API documentation and why is it important?',
			'What is a mock server, and why is it used in API testing?',
			'What is API mocking? How can it be used to enhance testing?',
			'How do you ensure data integrity while testing APIs?',
			'What is method chaining in API testing?',
		],
	},
	{
		q24: [
			'What are the common challenges faced while testing APIs and how would you overcome them?',
			'How do you validate responses in API testing?',
			'Describe a situation where you performed automation testing on APIs. What tools did you use and what was the outcome?',
			'How do you test APIs for performance and scalability?',
			'Can you automate your API calls through Postman ?',
			'If Postman can handle both manual and automation, then why do you need to use Cypress or another tool for API automation as well ?',
			'How do you handle API testing in Cypress?',
		],
	},
	{
		q25: [
			`
## Reverse String
Write a function named \`reverseString()\` which takes a \`string\` argument and returns the given \`string reversed\`. 

UNIT TESTS:
~~~js
reverseString("Hello World")          	-> "dlroW olleH"
reverseString("TechGlobal")          	-> "labolGhceT"
reverseString("Basketball is fun")   	-> "nuf si llabteksaB"
reverseString("")                       -> ""
reverseString("Apples 456")           	-> "654 selppA"
~~~
`,

			`
## Reverse Number
Write a function named \`reverseNumber()\` which takes a \`number\` argument and returns it back reversed without converting it to a String.
NOTE: Do not convert numbers to strings to complete the task.
 
UNIT TESTS:
~~~js
reverseNumber(371)          	-> 173
reverseNumber(123)          	-> 321
reverseNumber(12)            	-> 21
reverseNumber(0)              	-> 0
reverseNumber(111)          	-> 111
~~~
`,
			`
## Reverse String Words
Write a function named \`reverseStringWords()\` which takes a \`string\` as an argument and returns the string with each word separately reversed.
NOTE: Make your code dynamic that works for any string. Make sure you consider extra spaces before and after words in the given string.
 
UNIT TESTS:
~~~js
reverseStringWords("Hello World")            	-> "olleH dlroW"
reverseStringWords("I like JavaScript")     	-> "I ekil tpircSavaJ"
reverseStringWords("Hello")                     -> "olleH"
reverseStringWords("")                          -> ""
reverseStringWords(" ")                         -> ""
~~~
`,
			`
## Reverse Sentence
Write a function named \`reverseSentence()\` which takes a \`string\` as argument and returns the words in reverse order.​ If there are not enough words reverse, return "There are not enough words!".
 
UNIT TESTS:
~~~js
reverseSentence("Hello")                        -> "There are not enough words!"
reverseSentence("Javascript is fun")         	-> "Fun is javascript"
reverseSentence("A b c")                        -> "C b a"
reverseSentence("Five dollars")              	-> "Dollars five"
~~~
`,
			`
## Palindrome
Write a function named \`isPalindrome()\` which takes a \`string\` word as an argument and returns true if the word is palindrome or returns false otherwise.
NOTE: Palindrome: It is a word that is read the same backward as forward
Examples: kayak, civic, madam
NOTE: The function should ignore case sensitivity

UNIT TESTS:
~~~js
isPalindrome("Hello")         	-> false
isPalindrome("Kayak")        	-> true
isPalindrome("abba")          	-> true
isPalindrome("ab  a")          	-> false
isPalindrome("123454321")   	-> true
isPalindrome("A")               -> true
isPalindrome("")                -> true
~~~
`,
			`
## Count Palindrome
Write a function named countPalindrome() which takes a string and returns the number of palindrome words.
NOTE: A palindrome word is a word that reads the same forwards and backwards. Example: level, radar, deed, refer.
 
UNIT TESTS:
~~~js
countPalindrome("Mom and dad"​)              -> 2
countPalindrome("See you at noon")         	-> 1
countPalindrome("")                         -> 0
countPalindrome("No palindrome here")   	-> 0
 ~~~
 `,
			`
## Fizz Buzz
Write a function named \`fizzBuzz()\` which takes a \`number\` argument and returns an array consisting of numbers starting from 1 to the given number. 
However, it will return "Fizz" for the numbers divided by 3, "Buzz" for the numbers divided by 5, and "FizzBuzz" for the numbers divided by 3 and 5.
 
UNIT TESTS:
~~~js
fizzBuzz(3)  	-> [ 1, 2, 'Fizz' ]
fizzBuzz(5)  	-> [ 1, 2, 'Fizz', 4, 'Buzz' ]
fizzBuzz(10)	-> [ 1​, 2​, 'Fizz'​, 4​, 'Buzz'​, 'Fizz'​, 7, 8​, 'Fizz'​, 'Buzz' ]​
fizzBuzz(15)	-> [ 1​, 2​, 'Fizz'​, 4​, 'Buzz'​, 'Fizz'​, 7, 8​, 'Fizz'​, 'Buzz', 11, 'Fizz', 13, 14,  'FizzBuzz' ]​
fizzBuzz(2)  	-> [ 1, 2 ]
 ~~~
 `,
			`
## Factorial
Write a function named \`factorial()\` which takes a \`number\` as an argument and returns the factorial of the number.
NOTE: Mathematically, the factorial of a non-negative integer n is defined as: n! = n × (n-1) × (n-2) × ... × 2 × 1
NOTE: Assume you will not be given a negative number.

UNIT TESTS:
~~~js
factorial(5)   	-> 120
factorial(4)   	-> 24
factorial(0)   	-> 1
factorial(1)   	-> 1
 ~~~`,
			`
## Array Factorial
Write a function named \`arrFactorial()\` which takes an \`array\` of numbers as argument and returns the array with every number replaced with their factorials.

UNIT TESTS:
~~~js
arrFactorial([1, 2, 3 ,4])       	-> [1, 2, 6, 24]
arrFactorial([0, 5])               	-> [1,120]
arrFactorial([5 , 0, 6])          	-> [120, 1, 720]
arrFactorial([])                    -> []
~~~`,
			`
## Fibonacci Series
Write a function named \`fibonacciSeries()\` which takes a \`number\` n argument and returns the n series of Fibonacci numbers as an array. ​
NOTE: Fibonacci series = 0, 1, 1, 2, 3, 5, 8, 13, 21…
 
UNIT TESTS:
~~~js
fibonacciSeries(3)   	-> [0, 1, 1]
fibonacciSeries(5)   	-> [0, 1, 1, 2, 3]
fibonacciSeries(7)   	-> [0, 1, 1, 2, 3, 5, 8]
fibonacciSeries(8)   	-> [0, 1, 1, 2, 3, 5, 8, 13]
~~~`,
			`
## Is Armstrong
Write a function named \`isArmstrong()\` which takes a \`number\` argument and returns true if the given number is Armstrong, return false otherwise.​
NOTE: An Armstrong number is a number that is equal to the sum of its digits raised
to the power of the number of digits. In other words, an n-digit number is an Armstrong number if the sum of its digits, each raised to the nth power, is equal to the number itself.
Let's take an example to understand it better. Consider the number 153.
To determine if 153 is an Armstrong number, we need to check if the sum of its digits, each raised to the power of the number of digits, equals the original number.
1^3 + 5^3 + 3^3 = 1 + 125 + 27 = 153
In this case, the sum of the individual digits raised to the power of 3 (the number of digits in 153) is equal to the original number, which means 153 is an Armstrong number.
 
UNIT TESTS:
~~~js
isArmstrong(153)    -> true
isArmstrong(123)    -> false
isArmstrong(1634)  	-> true
isArmstrong(153)    -> true
isArmstrong(1111)  	-> false
~~~`,
			`
## Is Anagram
Write a function named \`isAnagram()\` which takes \`two string\` arguments and returns true if the given strings are anagram. Return false otherwise.
NOTE: An anagram is a word or phrase formed by rearranging the letters of another word or phrase. In the context of strings, checking if two strings are anagrams of each other means verifying if they contain the same characters in the same quantities, regardless of the order of those characters.
NOTE: This method is case-insensitive and ignores the white spaces.
 
UNIT TESTS:
~~~js
isAnagram("Apple", "Peach")​                -> false
isAnagram("listen", "silent")​              -> true
isAnagram("astronomer", "moon starer")​   	-> true
isAnagram("CINEMA", "iceman")​              -> true
isAnagram("123", "1234")                   -> false
 ~~~`,
			`
## Prime Number
Write a function named \`isPrime()\` which takes a \`number\` as an argument and returns true if the number is prime or returns false otherwise.
NOTE: Mathematically, the Prime number is a number that can be divided only by itself and 1. It cannot be divided by any other number. The smallest prime number is 2 and 2 is the only even prime number.
Examples: 2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31…
NOTE: The smallest prime number is 2 and there are no negative prime numbers.

UNIT TESTS:
~~~js
isPrime(5)   	-> true
isPrime(2)   	-> true
isPrime(29)     -> true
isPrime(-5)  	-> false
isPrime(0)   	-> false
isPrime(1)   	-> false
~~~`,
			`
## Count Prime Numbers
Write a function named \`countPrimes()\` which takes an \`array of integer\` numbers as an argument and will return the number of the prime numbers in the given array.
NOTE: Mathematically, the Prime number is a number that can be divided only by itself and 1. It cannot be divided by any other number. The smallest prime number is 2 and 2 is the only even prime number.
Examples: 2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31…
NOTE: The smallest prime number is 2 and there are no negative prime numbers.
 
UNIT TESTS:
~~~js
countPrimes([-10, -3, 0, 1])            -> 0
countPrimes([7, 4, 11, 23, 17])       	-> 4
countPrimes([41, 53, 19, 47, 67])   	-> 5
countPrimes([0])                        -> 0
countPrimes([2, 2, 2, 2, 2, 2])         -> 6
countPrimes([29,31,37])                 -> 3
~~~`,
			`
## Count Occurrences of Characters
Write a function named \`countOccurrencesOfCharacters()\` which takes a \`string\` argument and returns the count of repeated characters in the String.​
NOTE: If the given String is empty, then return the empty String.​
NOTE: It is case-sensitive.
 
UNIT TESTS:
~~~js
countOccurrencesOfCharacters("")        -> ""
countOccurrencesOfCharacters("abc")     -> "1a1b1c"
countOccurrencesOfCharacters("abbcca")	-> "1a2b2c1a"
countOccurrencesOfCharacters("aaAAa")	-> "2a2A1a"
countOccurrencesOfCharacters("www" )   	-> "3w"
~~~`,
			`
## Most Repeated Element in an Array
Write a method named mostRepeated() takes an array argument and returns the most counted element from the array.
NOTE: Assume that you will not be given an empty array and the count of one element will always be more than the others.

UNIT TESTS:
~~~js
mostRepeated([4, 7, 4, 4, 4, 23, 23, 23])                                       -> 4
mostRepeated(["pen", "pencil", "pen", "123", "abc", "pen", "pencil"])        	-> "pen"
mostRepeated(["TechGlobal"])                                                    -> "TechGlobal"
mostRepeated(['A', 'B', 'A', 'B', 'A'])                                         -> 'A'
~~~`,
			`
## The Biggest Number in a String
Write a function named \`biggestNumber()\` which takes a \`string\` argument and returns the biggest number appears in the string.
 
UNIT TESTS:
~~~js
biggestNumber("abc$")               -> 0
biggestNumber("a1b4c  6#")          -> 6
biggestNumber("ab110c045d")      	-> 110
biggestNumber("525")        	    -> 525
biggestNumber("3 for 10 dollars")  	-> 10
 ~~~`,
			`
## Sum of the Digits in a String
Write a function named \`sumDigits()\` which takes a \`string\` and returns the sum of the digits in the given String. Return -1 if the given string does not have any digits. Ignore negative numbers.
 
UNIT TESTS:
~~~js
sumDigits("Javascript")      -> -1
sumDigits("23abc45")​         -> 14
sumDigits("Hi-23")           -> 5
sumDigits("ab12")            -> 3
sumDigits("n0numh3r3")    	 -> 6
 ~~~`,
			`
## Sum of the Numbers in a String
Write a function named \`sumNumbers()\` which takes a \`string\` argument and returns sum of all numbers appears in the string.
 
UNIT TESTS:
~~~js
sumNumbers("abc$")                  -> 0
sumNumbers("a1b4c  6#")             -> 11
sumNumbers("ab110c045d")         	-> 155
sumNumbers("525")                   -> 525
sumNumbers("3 for 10 dollars")     	-> 13
~~~`,
			`
## Max Number in an Array
Write a method named \`max()\` takes an \`array argument\` and returns the max number from the array.
NOTE: Assume that you will not be given an empty array.
 
UNIT TESTS:
~~~js
max([7, 4, 4, 4, 23, 23, 23])   -> 23
max([3, 4, 5, 6])               -> 6
max([10])                       -> 10
max([-1, -2, -3, -4]])          -> -1
max([0])                        -> 0
max([1, 2])                     -> 2
~~~`,
			`
## Min Number in an Array
Write a method named \`min()\` takes an \`array\` argument and returns the min number from the array.
NOTE: Assume that you will not be given an empty array.
 
UNIT TESTS:
~~~js
min([7, 4, 4, 4, 23, 23, 23]) 	-> 4
min([3, 4, 5, 6])               -> 3
min([10])                       -> 10
min([-1, -2, -3, -4])           -> -4
min([0])                        -> 0
min([1, 2])                     -> 1
 ~~~`,
			`
## Second Max Number in an Array
Write a method named \`secondMax()\` takes an \`array\` argument and returns the second max number from the array.
NOTE: Assume that you will not be given an empty array and if the array has only 1 element, it will be returned as a second max number.
NOTE: Be careful when there are multiple max numbers.

UNIT TESTS:
~~~js
secondMax([7, 4, 4, 4, 23, 23, 23])   -> 7
secondMax([3, 4, 5, 6])               -> 5
secondMax([10])                       -> 10
secondMax([-1, -2, -3, -4]])          -> -2
secondMax([0])                        -> 0
~~~`,
			`
## Second Min Number in an Array
Write a method named \`secondMin()\` takes an \`array\` argument and returns the second min number from the array.
NOTE: Assume that you will not be given an empty array and if the array has only 1 element, it will be returned as a second min number.
NOTE: Be careful when there are multiple min numbers.

UNIT TESTS:
~~~js
secondMin([7, 4, 4, 4, 23, 23, 23]) 	-> 7
secondMin([3, 4, 5, 6])                 -> 4
secondMin([10])                         -> 10
secondMin([-1, -2, -3, -4])             -> -3
secondMin([0])                          -> 0
secondMin([1, 2])                       -> 2
 ~~~`,
			`
## Count Words
Write a function named \`countWords()\` which takes a \`string\` argument and returns the total count of words in the given string.
NOTE: Be careful about the extra whitespaces before and after the string.

Examples:
~~~js
countWords("     Javascript is fun       ")                     -> 3
countWords("Cypress is a UI automation tool.    ")           	-> 6 
countWords("1 2 3 4")                                           -> 4
 ~~~`,
			`
## Remove Extra Spaces
Write a function named removeExtraSpaces() which takes a string word as an argument and returns the string back with all extra spaces removed.

UNIT TESTS:
~~~js
removeExtraSpaces("Hello")                                      -> "Hello"
removeExtraSpaces("        	Hello    World ")                 	-> "Hello World"
removeExtraSpaces("        	JavaScript is           	fun")   -> "JavaScript is fun"
removeExtraSpaces("")                                           -> "" 
 ~~~`,
			`
## Middle Number of Three
Write a function named \`middle()\` which takes \`three number\` arguments and return the middle number. ​

UNIT TESTS:
~~~js
middle(1, 2, 2)         	-> 2
middle(5, 5, 8)         	-> 5
middle(5, 3, 5)         	-> 5
middle(1, 1, 1)         	-> 1
middle(-1, 25, 10)   		-> 10
~~~`,
			`
## First Duplicate Element
Write a function named as \`firstDuplicate()\` which takes an \`array\` argument and returns the first duplicated number in the array when invoked.
NOTE: Make your code dynamic that works for any array and return -1 if there are no duplicates in the array. For two elements to be considered duplicated, the value and data types of the elements must be the same.
 
UNIT TESTS:
~~~js
firstDuplicate([ 3, 7, 10, 0, 3, 10 ])              	-> 3
firstDuplicate([ 5, 7, 7, 0, 5, 10 ])                	-> 5
firstDuplicate([ 5, '5', 3, 7, 4 ])                     -> -1
firstDuplicate([ 123, 'abc', '123', 3, 'abc' ])  		-> 'abc'
firstDuplicate([ 1, 2, 3])                              -> -1
firstDuplicate([ 'foo', 'abc', '123', 'bar' ])      	-> -1
~~~`,
			`
## All The Duplicate Elements
Write a function named as \`getDuplicates()\` which takes an \`array\` argument and returns all the duplicate elements in the array.
NOTE: Make your code dynamic that works for any array and return an empty array if there are no duplicates in the array. For two elements to be considered duplicated, the value and data types of the elements must be the same.
 
UNIT TESTS:
~~~js
getDuplicates([ 0, -4, -7, 0, 5, 10, 45, -7, 0 ])                     	-> [ 0, -7 ]
getDuplicates([ 1, 2, 5, 0, 7 ])                                        -> [ ]
getDuplicates(['A', 'foo', '12', 12, 'bar', 'a', 'a', 'foo' ])  		-> [ 'foo', 'a' ]
getDuplicates([ 'foo', '12', 12, 'bar', 'a' ])                          -> [ ]
~~~`,
			`
## Remove Duplicates
Write a function named \`removeDuplicates()\` which takes an \`array\` argument and returns a new array with all the duplicates removed.

UNIT TESTS:
~~~js
removeDuplicates([10, 20, 35, 20, 35, 60, 70, 60])           	-> [10, 20, 35, 60, 70]
removeDuplicates([1, 2, 5, 2, 3])                               -> [1, 2, 5, 3]
removeDuplicates([0, -1, -2, -2, -1])                       	-> [0, -1, -2]
removeDuplicates(["abc", "xyz", "123", "ab", "abc", "ABC"]) 	-> ["abc", "xyz", "123", "ab", "ABC"]
removeDuplicates(["1", "2", "3", "2", "3"])                     -> ["1", "2", "3"]
 ~~~`,
			`
## Add Arrays
Write a function named \`add()\` which takes \`two arrays\` of numbers as arguments and returns a new array with the sum of given arrays elements.
 
UNIT TESTS:
~~~js
add([3, 0, 0, 7, 5, 10], [6, 3, 2])                         -> [9, 3, 2, 7, 5, 10]
add([10, 3, 6, 3, 2], [6, 8, 3, 0, 0, 7, 5, 10, 34])      	-> [16, 11, 9,  3, 2, 7, 5, 10, 34]​
add([-5, 6, -3, 11], [5, -6, 3, -11])                       -> [0, 0, 0, 0]
add([1, 2, 3, 4], [])                                       -> [1, 2, 3, 4]
add([], [])                                                 -> []
 ~~~`,
			`
## Get Common Elements from Arrays
Write a function named \`getCommons()\` which takes \`two string arrays\` as arguments and returns all the common words.
 
UNIT TESTS:
~~~js
getCommons( ["abc", "xyz", "123"])                                              -> []
getCommons( ["Javascript", "is", "fun"], ["Javascript", "C#", "Python"])    	-> ["Javascript"]
getCommons( ["Javascript", "C#", "C#"], ["Python", "C#", "C++"])            	-> ["C#"]
getCommons( ["a", "b", "c"], ["a", "b", "c"])                                   -> ["a", "b", "c"]
getCommons( ["a", "b", "c", "a", "b", "c"], ["a", "b", "c", "a", "b", "c"])     -> ["a", "b", "c"]
 ~~~`,
			`
## Categorize Characters
Write a function named \`categorizeCharacters()\` which takes a \`string word\` as argument and return an array as letters at index of 0, digits at index of 1 and specials at index of 2. 
 
UNIT TESTS:
~~~js
categorizeCharacters("1234")        	-> [ '' , '1234', '' ] 
categorizeCharacters("abc123$#%")       -> [ 'abc', '123', '$#%' ]
categorizeCharacters("12ab$%3c%")       -> [ 'abc', '123', '$%%' ]
categorizeCharacters("")                -> [ '' , '', '' ] 
 ~~~`,
			`
## Is Power Of 3
Write a function named \`isPowerOf3()\` which takes a \`number\` argument and returns true if given number is equal to 3 power of the X. Otherwise, return false.​
NOTE: Numbers that are power of 3 = 1, 3, 9, 27, 81, 243….
NOTE: Ignore negative scenarios.
 
UNIT TESTS:
~~~js
isPowerOf3(1)         	-> true
isPowerOf3(2)         	-> false
isPowerOf3(3)         	-> true
isPowerOf3(27)       	-> true
isPowerOf3(100)     	-> false
isPowerOf3(81)       	-> true
isPowerOf3(9)         	-> true
 ~~~`,
			`
## Split String
Write a function named \`splitString()\` which takes a \`string\` and a number arguments and returns the string back split by the given number. 
NOTE: Return an empty string if the string is shorter than the splitting number or the string length is not divisible by the given number.
 
UNIT TESTS:
~~~js
splitString("JavaScript", 5)  	-> "JavaS cript"
splitString("Java", 2)          -> "Ja va"
splitString("Automation", 3)	-> ""
splitString("Hello", 6)         -> ""
splitString("12", 1)            -> "1 2"
 ~~~`,
			`
## Can Form String
Write a function named \`canFormString()\` which takes \`two string\` arguments and returns true if the second string can be formed by rearranging the characters of the first string. Return false otherwise.
NOTE: This method is case-insensitive and ignores the white spaces.
 
UNIT TESTS:
~~~js
canFormString("Hello", "Hi")​                           -> false
canFormString("programming", "gaming")​                 -> true
canFormString("halogen", "hello")​                      -> false
canFormString("CONVERSATION", "voices rant on")​      	-> true
~~~`,
			`
## Median of Two Sorted Arrays
Write a function named \`median()\` which takes \`two arrays\` of numbers as its arguments and return the median of the two sorted arrays. 
NOTE: The median is the middle number of a sorted array. So, the median of [1,3], [2] would be 2. If the array has an even length, you are to find the average of the 2 middle numbers.
 
UNIT TESTS:
~~~js
median([1, 3], [2])               	-> 2
median([1, 2], [3, 4])           	-> 2.5
median([4], [3])                   	-> 3.5
median([4], [])                     -> 4
median([0], [0,1])                  -> 0
~~~`,
			`
## Is Perfect Square
Write a function named \`isPerfectSquare()\` which takes a \`number\` as an argument and checks if it is a perfect square. It returns true if the number is a perfect square and false otherwise.
NOTE: A perfect square is a number that can be expressed as the product of an integer by itself or as the second exponent of an integer. For example, 25 is a perfect square because it is the product of integer 5 by itself, 5 × 5 = 25. However, 21 is not a perfect square number because it cannot be expressed as the product of two same integers.
 
UNIT TESTS:
~~~js
isPerfectSquare(25)   -> true
isPerfectSquare(24)   -> false
isPerfectSquare(0) 	  -> true
isPerfectSquare(-1)   -> false
isPerfectSquare(144)  -> true
~~~`,
			`
## Longest Word in a String
Write a function named \`longestWord()\` which takes a \`string\` as input and returns the longest word in the string.
NOTE: If the string is empty or consists of spaces only, then return an empty string.
NOTE: If the string consists of multiple words having the longest word, then return the first occurrence.
 
UNIT TESTS:
~~~js
longestWord("The quick brown fox jumped over the lazy dog")    	-> "jumped"
longestWord("This is a sample string for testing")              ->"testing" 
longestWord("One two ten")                                      -> "One"
longestWord("")                                                 -> ""
longestWord("      ")                                           -> ""
 ~~~`,
			`
## Nth Chars
Write a function named \`nthChars()\` which takes a \`string\` and a number as arguments and returns the string back with every nth character.​
 
UNIT TESTS:
~~~js
nthChars("Java", 2)            	-> "aa"
nthChars("JavaScript", 5)​   	 -> "St"
nthChars("Java", 3)​             -> "v"
nthChars("Hi", 4)​               -> ""
nthChars("0123456789", 2)		-> "13579" 
 ~~~`,
			`
## Sum Even Odd Indexes
Write a function named \`sum()\` which takes an \`array\` of numbers and a boolean value as arguments. It will return the sum of the numbers positioned at even indexes if the boolean value is true. And return the sum of numbers positioned at odd indexes if the boolean value is false.
 
UNIT TESTS:
~~~js
sum([1, 5, 10], true)           -> 11
sum([3, 7, 2, 5, 10], false)   	-> 12
sum([-1, 1, -2, 2], true)       -> -3
sum([0, -1, 15, 1], false)     	-> 0
sum([1, 2, 3, 4, -4], true)     -> 0
~~~
`,
		],
	},
];

export const MOCK_QUESTIONS_5 = [
	{
		q1: [
			'Tell me about yourself?',
			'Tell about all your projects you worked for the companies you have in your resume?',
			'Tell me about your UI/API frameworks?',
		],
	},
	{
		q2: [
			'Describe your experience with using BDD (Behavior-Driven Development) for test automation?',
			'Describe your experience with using TDD (Test-Driven Development) for test automation?',
		],
	},
	{
		q3: [
			'Tell me about your Team/Environment and how you utilize Agile in your company?',
			'How did you start your career in the QA field?',
			'What are the most important skills to have in Quality Assurance and why?',
		],
	},
	{
		q4: [
			'When do you think testing should start in SDLC?',
			'How do you start testing a component? What is your process of testing?',
			'What do you do when the requirements are not clear for your UI/API testing?',
			'How do you decide if you have enough test coverage for requirements?',
		],
	},
	{
		q5: [
			'Tell me about your Smoke and Regression Suites?',
			'How do you decide if a test case is a good candidate for Smoke or Regression Suites?',
			'Can you explain how you integrate your test automation suites with the Continuous Integration (CI) process?',
			'How do you decide if a test case should be manual or automated?',
		],
	},
	{
		q6: [
			'What are some daily challenges you face?',
			'Describe a scenario where you had to work on multiple projects/responsibilities simultaneously and how you managed your time effectively?',
			'Describe a situation where you had to prioritize testing tasks due to limited time or resources?',
			'Can you share a situation where you had to deal with a testing emergency, such as a production issue, and how you resolved it?',
			'Can you share an example of a situation where you had to lead a testing effort and coordinate with other team members?',
			'Can you share an example of a complex test scenario you automated and the challenges you faced during the process? How did you resolve it?',
			'What is the most interesting script you automated recently?',
		],
	},
	{
		q7: [
			'How do you decide to create a bug ticket when you face an issue while testing?',
			'What do you include in your bug ticket?',
			'Could you please tell me a few bugs that you recently found? Tell me the most interesting and recent one?',
			'Describe a situation where you encountered a critical bug just before a release and how you managed the situation?',
			'How do you collaborate with developers to address and resolve defects effectively?',
		],
	},
	{
		q8: [
			'What do you do in a regular sprint?',
			'How do you perform UI testing?',
			'How do you perform API testing?',
			'How do you perform Performance testing?',
		],
	},
	{
		q9: [
			'Tell me a situation that you felt so stressed and how did you overcome?',
			'Tell me a situation that you went above and beyond?',
			'Tell me about your biggest achievements-accomplishments in your QA career?',
			'How do you stay updated with industry trends and emerging technologies in the QA and testing domain? Tell me something that you recently learned and implemented in your work?',
		],
	},
	{
		q10: [
			'Why are you leaving your current company?',
			'What made you apply for this position/company? And what makes you a good fit for this position?',
			'What is your expectation from the company if you are hired?',
			'What would be your steps if we hire you and ask you to create a framework from scratch?',
		],
	},
];

const MOCK_QUESTIONS = {
	MOCK_QUESTIONS_1,
	MOCK_QUESTIONS_2,
	MOCK_QUESTIONS_3,
	MOCK_QUESTIONS_4,
	MOCK_QUESTIONS_5,
};
export default MOCK_QUESTIONS;
